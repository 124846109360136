import { useCookies } from 'react-cookie'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { authApi } from '../redux/api/auth-api'
import FullScreenLoader from './FullScreenLoader'

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const [cookies] = useCookies(['logged_in'])
  const logged_in = cookies.logged_in

  const { data: user } = authApi.endpoints.profile.useQuery(null, {
    skip: !logged_in
  })

  // if(!isSuccess || !isUninitialized) {
  //   const  { data: userRefresh, isSuccess : isSuccessRefresh } = authApi.endpoints.refresh.useQuery(null, {
  //     skip: !logged_in,
  //   });
  //
  //   if(userRefresh && isSuccessRefresh) {
  //     // forceUpdate()
  //     console.log('ура', userRefresh, isSuccessRefresh)
  //   }
  // }

  const location = useLocation()

  if (logged_in && !user) {
    return <FullScreenLoader />
  }

  return logged_in && allowedRoles.includes(user?.role as string) ? (
    <Outlet />
  ) : logged_in && user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequireUser
