import { LoadingButton } from '@mui/lab'
import { AppBar, Avatar, Box, Container, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import { useLogoutUserMutation } from '#api/auth-api'
import { useAppSelector } from '#store'
import { getFullName } from '#utils'

import { MenuItems } from './components'

const Header = () => {
  const [cookies] = useCookies(['logged_in'])
  const logged_in = cookies.logged_in

  const navigate = useNavigate()

  const [logoutUser, { isLoading, isSuccess }] = useLogoutUserMutation()
  const user = useAppSelector((state) => state.userState.user)
  const users = useAppSelector((state) => state.userState.users)

  const getUserFullName = () => {
    const userProfile = users.find(({ id }) => id === user?.id)

    if (!userProfile) {
      return ''
    }

    return getFullName(userProfile)
  }

  const fullName = useMemo(() => getUserFullName(), [user, users])

  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/login'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const onLogoutHandler = async () => {
    logoutUser()
  }

  return (
    <AppBar color="default" position="fixed">
      <Container fixed sx={{ minWidth: '95%' }}>
        <Toolbar>
          <Typography variant="h6" onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            УКС «АВИТЕК» <Typography variant="caption">({fullName})</Typography>
          </Typography>
          <Box display="flex" sx={{ ml: 'auto' }}>
            {!logged_in && (
              <>
                <LoadingButton onClick={() => navigate('/login')}>Login</LoadingButton>
              </>
            )}

            <MenuItems role={user?.role} loggedIn={logged_in} />

            <Box sx={{ ml: 2 }}>
              <Tooltip title="Личный кабинет" onClick={() => navigate('/profile')}>
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt="Войти" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
            </Box>

            {logged_in && (
              <LoadingButton sx={{ ml: 2 }} onClick={onLogoutHandler} loading={isLoading}>
                Выйти
              </LoadingButton>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export { Header }
