import { Box, Button, Fade, Grid, Paper, Popper } from '@mui/material'

type ButtonProps = {
  accept: string
  decline: string
  acceptCallback: (...arg: unknown[]) => void
}

type Props = {
  title: string
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  anchorEl: HTMLButtonElement | null
  button: ButtonProps
}

const PopperConfirmation = ({ title, isOpen, setIsOpen, anchorEl, button }: Props) => {
  const { accept, decline, acceptCallback } = button

  return (
    <Popper sx={{ zIndex: 1200 }} open={isOpen} anchorEl={anchorEl} placement="bottom" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Box sx={{ p: 2 }}>
              {title}
              <Grid sx={{ mt: 2 }} container justifyContent="space-between">
                <Grid item>
                  <Button sx={{ mr: 2 }} color="error" variant="contained" onClick={acceptCallback}>
                    {accept}
                  </Button>
                  <Button variant="outlined" onClick={() => setIsOpen(false)}>
                    {decline}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

export { PopperConfirmation }
