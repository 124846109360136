import { RemarksByApartment } from '#api/types'

import { GridBase, GridModify } from '../../style'

type Props = {
  remarks: RemarksByApartment[]
}

const RemarksAcceptance = ({ remarks }: Props) => {
  const filterAcceptanceRemarks = remarks.filter(({ user_verify_id }) => !user_verify_id)

  return (
    <>
      <GridModify item xs={12}>
        Суть обращения:
      </GridModify>
      <GridModify item xs={12}>
        {filterAcceptanceRemarks.map(({ description, remark: { name = '' } = {} }, idx) => (
          <GridBase key={idx} container spacing={1}>
            <GridBase item xs={6}>
              {name}
            </GridBase>
            <GridBase item xs={6}>
              {description}
            </GridBase>
          </GridBase>
        ))}
      </GridModify>
    </>
  )
}

export { RemarksAcceptance }
