import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { NotesByType } from '#api/types'

import { AccordionRemark } from '../accordion-remark'

type Props = {
  note: NotesByType
}

const AccordionProperty = ({ note }: Props) => (
  <Accordion expanded={true}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${note.id}-content`} id={`note-${note.id}`}>
      <b>{note.slug}</b>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <b>Наименование</b>
        </Grid>
        <Grid item xs={12} md={3}>
          <b>Группа</b>
        </Grid>
        <Grid item xs={12} md={3}>
          <b>Исполнитель</b>
        </Grid>
        <Grid item xs={12} md={1}>
          <b>Период</b>
        </Grid>
        <Grid item xs={12} md={2} container direction="row" justifyContent="flex-end" alignItems="center">
          <b>Действие</b>
        </Grid>
      </Grid>

      {!note.remarks.length && <p>Замечаний не обнаружено</p>}

      {note.remarks.map((remark, id) => (
        <AccordionRemark
          key={`${remark.id}-remark-${id}-${remark.period}-${remark.user_id}-${remark.name}`}
          typeId={note.id}
          remark={remark}
        />
      ))}
    </AccordionDetails>
  </Accordion>
)

export { AccordionProperty }
