import DeleteIcon from '@mui/icons-material/Delete'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'

import { useDeleteDocumentMutation, useHouseMutation } from '#api/house-api'
import { Apartment } from '#api/types'
import { useAppSelector } from '#store'
import { isAdmin } from '#utils'

type Props = {
  apartment: Apartment
}

const DeleteDocument = ({ apartment }: Props) => {
  const [deleteDocument] = useDeleteDocumentMutation()
  const user = useAppSelector((state) => state.userState.user)
  const isAdminRight = isAdmin(user)
  const [getHouse] = useHouseMutation()

  if (!isAdminRight || !apartment.document) {
    return null
  }

  const onDestroy = async () => {
    const data = await deleteDocument({
      apartment_id: apartment.id,
      house_id: apartment.house_id
    })

    if ((data as any).data?.data) {
      getHouse(apartment.house_id)
    } else {
      toast.error('При удалении произошла ошибка, обратитесь к администратору')
    }
  }

  return (
    <Button
      onClick={() => onDestroy()}
      type="submit"
      color="error"
      variant="outlined"
      size="small"
      aria-label="delete"
      sx={{ ml: 1 }}
    >
      <DeleteIcon />
    </Button>
  )
}

export { DeleteDocument }
