import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApartmentsAcceptanceType, House, RemarksByApartment } from '#api/types'

interface IHouseState {
  types: ApartmentsAcceptanceType[] | null
  remarksByApartment: RemarksByApartment[] | null
  remarksApartmentsByHome: RemarksByApartment[]
  houses: House[]
  house: House | null
}

const initialState: IHouseState = {
  types: [],
  remarksByApartment: [],
  remarksApartmentsByHome: [],
  houses: [],
  house: null
}

export const houseSlice = createSlice({
  initialState,
  name: 'houseSlice',
  reducers: {
    setAcceptanceType: (state, action: PayloadAction<ApartmentsAcceptanceType[]>) => {
      state.types = action.payload
    },
    setRemarksByApartment: (state, action: PayloadAction<RemarksByApartment[]>) => {
      state.remarksByApartment = action.payload
    },
    setRemarksApartmentsByHome: (state, action: PayloadAction<RemarksByApartment[]>) => {
      state.remarksApartmentsByHome = action.payload
    },
    setHouses: (state, action: PayloadAction<House[]>) => {
      state.houses = action.payload
    },
    setHouse: (state, action: PayloadAction<House>) => {
      state.house = action.payload
    }
  }
})

export default houseSlice.reducer

export const { setAcceptanceType, setHouses, setRemarksByApartment, setRemarksApartmentsByHome, setHouse } =
  houseSlice.actions
