import { useState } from 'react'
import { toast } from 'react-toastify'

import { User } from '#api/types'
import { useDestroyUsersByIdsMutation, useUsersMutation } from '#api/userApi'
import { useAppSelector } from '#store'
import { declension } from '#utils'

import { UsersDeclensions } from '../utils/constants'

const useMethods = () => {
  const users = useAppSelector((state) => state.userState.users)

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<number[]>([])
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const [destroyUsersByIds] = useDestroyUsersByIdsMutation()
  const [getUsers] = useUsersMutation()

  const selectedByUser = (findId: number) => {
    const user = (users ?? []).find(({ id }) => findId === id)

    if (user) {
      setSelectedUser(user)
      setOpen(true)
    }
  }

  const destroyUsers = async (userIds: number[]) => {
    const result = await destroyUsersByIds({ userIds })

    if ('data' in result && typeof result.data === 'number') {
      const successMessage = `${result.data} ${declension(result?.data, UsersDeclensions)} удален(ны)`
      toast.success(successMessage)
      setSelected([])
      await getUsers(null)
    }
  }

  const openNewSidebar = () => {
    setSelectedUser(null)
    setOpen(true)
  }

  return {
    openNewSidebar,
    destroyUsers,
    selectedByUser,
    selected,
    open,
    selectedUser,
    setSelectedUser,
    setSelected,
    setOpen
  }
}

export { useMethods }
