import { useHousesMutation, useTypesMutation } from '#api/house-api'
import { useRolesMutation, useUsersMutation } from '#api/userApi'

const usePreloadingData = () => {
  const [getTypes, { isLoading: isTypeLoading, isSuccess: isTypeSuccess }] = useTypesMutation()
  const [getHouses, { isLoading: isHouseLoading, isSuccess: isHouseSuccess }] = useHousesMutation()
  const [getUsers, { isLoading: isUsersLoading, isSuccess: isUsersSuccess }] = useUsersMutation()
  const [getRoles, { isLoading: isRolesLoading, isSuccess: isRolesSuccess }] = useRolesMutation()

  const fetchAllCollection = async () => {
    const allPromiseCollection = await Promise.all([getTypes(null), getHouses(null), getUsers(null), getRoles(null)])

    return {
      allPromiseCollection,
      debug: {
        types: {
          isLoading: isTypeLoading,
          isSuccess: isTypeSuccess
        },
        houses: {
          isLoading: isHouseLoading,
          isSuccess: isHouseSuccess
        },
        users: {
          isLoading: isUsersLoading,
          isSuccess: isUsersSuccess
        },
        roles: {
          isLoading: isRolesLoading,
          isSuccess: isRolesSuccess
        }
      }
    }
  }

  return {
    fetchAllCollection
  }
}

export { usePreloadingData }
