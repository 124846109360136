import { IUser, Roles } from '#api/types'

const isSpecialist = (user: IUser | null) => {
  if (!user) {
    return false
  }

  return user.role === Roles.SPECIALIST
}

export { isSpecialist }
