import { type RemarksByApartment, type RemarksByType } from '#api/types'

const buildRemarksByType = (remarks: RemarksByApartment[]): RemarksByType =>
  remarks.reduce((acc, remark) => {
    const { apartments_acceptance_types_id } = remark

    return {
      ...acc,
      [apartments_acceptance_types_id]:
        apartments_acceptance_types_id in acc ? [...acc[apartments_acceptance_types_id], remark] : [remark]
    }
  }, {} as RemarksByType)

export { buildRemarksByType }
