import { ApartmentType } from '#api/types'

const NameByType: {
  [key in ApartmentType]: string
} = {
  [ApartmentType.Apartment]: 'Квартира',
  [ApartmentType.Office]: 'Офис',
  [ApartmentType.Storage]: 'Кладовка'
}

const getNameByType = (type: ApartmentType) => NameByType[type]

export { getNameByType, NameByType }
