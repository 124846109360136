import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'

import { FormInput } from '#components'

import { useLogin } from './hooks'
import { LoginContainer, StyleBox, StyleBoxFormProvider, StyleImage, StyleTypography } from './style'

const LoginPage = () => {
  const { isLoading, methods, handleSubmit, onSubmitHandler } = useLogin()

  return (
    <LoginContainer maxWidth={false}>
      <Box sx={StyleBox}>
        <img style={StyleImage} src="./logo.jpg" alt="" />

        <Typography variant="body1" component="h2" sx={StyleTypography}>
          Войдите в систему, чтобы получить доступ
        </Typography>

        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            maxWidth="27rem"
            width="100%"
            sx={StyleBoxFormProvider}
          >
            <FormInput mb={2} name="email" label="Email" type="email" />
            <FormInput mb={2} name="password" label="Password" type="password" />

            <LoadingButton
              variant="contained"
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              Войти
            </LoadingButton>
          </Box>
        </FormProvider>
      </Box>
    </LoginContainer>
  )
}

export default LoginPage
