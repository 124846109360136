import { number, object, string, TypeOf } from 'zod'

const updateNoteSchema = object({
  id: number().optional(),
  category_id: number(),
  period: string().min(1).max(150),
  name: string().min(3).max(150),
  user_id: number()
})

type UpdateNoteInput = TypeOf<typeof updateNoteSchema>

export { updateNoteSchema, type UpdateNoteInput }
