import { RemarksByApartment, UnionRemarksByApartment } from '#api/types'

type Props = {
  currentRemark: RemarksByApartment | UnionRemarksByApartment
  remarks: RemarksByApartment[]
}

const getRemarksByAcceptanceTypeId = ({ currentRemark, remarks }: Props) => {
  const { apartments_acceptance_types_id: typeId } = currentRemark

  return remarks.filter(({ apartments_acceptance_types_id: itemTypeId }) => typeId === itemTypeId)
}

export { getRemarksByAcceptanceTypeId }
