import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useAcceptancesMutation } from '#api/note-api'
import { House, IUser, type RemarksByApartment } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'
import { getFullAddress } from '#utils'

import { HeadCell } from '../../../components/table/type'
import { ActionsHouse } from '../components'

type Props = {
  remarks: RemarksByApartment[]
  houseId: number
  isExpertRight: boolean
  isPriorityRight: boolean
  user: IUser | null
}

const getAllRemarksUser = ({ remarks, isExpertRight, isPriorityRight, houseId, user }: Props) => {
  if (!isPriorityRight || !user) {
    return 0
  }

  const remarksByHome = isExpertRight
    ? remarks.filter(
        (remark) =>
          remark.apartment?.house_id === houseId &&
          remark.user_id === user?.id &&
          remark.user_verify_id === null &&
          !remark.accepted
      )
    : remarks.filter(
        (remark) =>
          remark.apartment?.house_id === houseId &&
          !remark.user_verify_id &&
          remark?.accepted &&
          remark.user_specialist_id === user?.id
      )

  return remarksByHome.length
}

const useHouseDataTable = () => {
  const [getRemarksAllHouses] = useAcceptancesMutation()
  const houses = useAppSelector((state) => state.houseState.houses)
  const remarks = useAppSelector((state) => state.noteState.remarks)

  const { user, isAdminRight, isPriorityRight, isExpertRight } = useRightCurrentUser()

  useEffect(() => {
    getRemarksAllHouses(null)
  }, [])

  const headCells: HeadCell<House>[] = [
    {
      id: 'street',
      numeric: false,
      disablePadding: false,
      label: 'Адрес',
      align: 'left',
      render: (_: string, house: House) => {
        const address = getFullAddress(house)
        const count = getAllRemarksUser({ remarks, isExpertRight, isPriorityRight, houseId: house.id, user })

        return (
          <Grid sx={{ pr: 2 }} alignItems="center" container>
            <Link to={`/apartments/${house.id}`}>{address}</Link>
            {count > 0 && (
              <Grid
                sx={{
                  ml: 1,
                  color: 'darkred',
                  display: 'flex',
                  backgroundColor: 'rgba(255,255,255, 0.7)',
                  borderRadius: '5px',
                  padding: '0 5px',
                  border: '1px solid darkred'
                }}
                alignItems="center"
              >
                {count}
              </Grid>
            )}
          </Grid>
        )
      }
    },
    {
      id: 'director',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: 'Директор'
    },
    {
      id: 'developer',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: 'Застройщик'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: 'Дата создания',
      render: (data) => {
        const dataToLocal = new Date(data).toLocaleDateString()

        return <>{dataToLocal}</>
      }
    }
  ]

  if (isAdminRight) {
    headCells.push({
      id: 'id',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: '',
      style: {
        width: '120px'
      },
      render: (id, house) => (
        <>
          <ActionsHouse id={id} house={house} />
        </>
      )
    })
  }

  return {
    houses,
    headCells
  }
}

export { useHouseDataTable }
