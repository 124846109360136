import { Delete, Edit } from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'
import { FormProvider } from 'react-hook-form'

import { Note } from '#api/types'
import { FormInput, FormSelect } from '#components'
import { useAppSelector } from '#store'

import { useMethods } from '../accordion-property/hooks'
import { optionsUsers as getOptionsUsers } from '../accordion-property/utils'

type Props = {
  remark: Note
  typeId: number
}

const AccordionRemark = ({ remark, typeId }: Props) => {
  const { onDestroyNote, onSubmitHandler, handleSubmit, methods } = useMethods({ remark, typeId })
  const users = useAppSelector((state) => state.userState.users) ?? []
  const types = useAppSelector((state) => state.houseState.types) ?? []
  const optionsUsers = getOptionsUsers(users)

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormInput size="small" mb={0} name="name" />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSelect size="small" mb={0} name="category_id" type="number" options={types} />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSelect size="small" mb={0} name="user_id" type="number" options={optionsUsers} />
          </Grid>
          <Grid item xs={12} md={1}>
            <FormInput size="small" mb={0} name="period" type="number" />
          </Grid>

          <Grid item xs={12} md={2} container direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton type="submit" color="primary" aria-label="edit">
              <Edit />
            </IconButton>

            <IconButton aria-label="delete" color="error" onClick={onDestroyNote}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  )
}

export { AccordionRemark }
