import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { MouseEvent, useState } from 'react'

import { PopperConfirmation } from '#components'

type Props = {
  selected: number[]
  selectedByUser: (findId: number) => void
  destroyUsers: (selected: number[]) => void
}

const ToolbarUser = ({ selected, selectedByUser, destroyUsers }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  const handleClick = () => (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const button = {
    accept: 'Удалить',
    decline: 'Отмена',
    acceptCallback: () => destroyUsers(selected)
  }

  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title="Редактировать" onClick={() => selectedByUser(selected[0])}>
        <IconButton disabled={selected.length !== 1}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <PopperConfirmation
        title="Вы точно хотите удалить записи"
        isOpen={open}
        setIsOpen={setOpen}
        anchorEl={anchorEl}
        button={button}
      />

      <Tooltip title="Удалить">
        <IconButton onClick={handleClick()}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}

export { ToolbarUser }
