import { zodResolver } from '@hookform/resolvers/zod'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { number, object, string, TypeOf } from 'zod'

import { useCreateNoteMutation } from '#api/note-api'
import { Roles } from '#api/types'
import { FormInput, FormSelect } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'

import { NoteContext } from '../../context'
import { buildOptions } from '../../utils'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

const createNoteSchema = object({
  category_id: number(),
  period: string().min(1).max(150).optional(),
  name: string().min(3).max(150),
  user_id: number()
})

export type CreateNoteInput = TypeOf<typeof createNoteSchema>

const SidebarNotes = () => {
  const users = useAppSelector((state) => state.userState.users) ?? []
  const types = useAppSelector((state) => state.houseState.types) ?? []
  const { getNotesByType, setIsOpen, isOpen } = useContextSafe(NoteContext)

  const methods = useForm<CreateNoteInput>({
    resolver: zodResolver(createNoteSchema)
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  const [createNote, { isLoading, isSuccess }] = useCreateNoteMutation()

  useEffect(() => {
    if (isSuccess) {
      toast.success('Замечание успешно зарегистрированно')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<CreateNoteInput> = async (values) => {
    await createNote(values as CreateNoteInput)
    getNotesByType(null)
    setIsOpen(false)
  }

  const optionsUsers = buildOptions(
    users.filter((user) => (user?.roles ?? []).map(({ name }) => name).includes(Roles.EXPERT))
  )

  const DrawerList = (
    <Box sx={{ width: '600px' }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{
            p: { xs: '1rem', sm: '2rem' }
          }}
        >
          <FormSelect name="category_id" type="number" label="Тип *" options={types} />

          <FormInput name="name" label="Название замечания *" />

          <FormInput name="period" type="number" label="Период исправления *" />

          <FormSelect name="user_id" type="number" label="Исполнитель *" options={optionsUsers} />

          <LoadingButton
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            disableElevation
            type="submit"
            loading={isLoading}
          >
            Создать
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  )

  return (
    <div>
      <Drawer open={isOpen} role="presentation" anchor="right">
        <DrawerHeader>
          <IconButton onClick={() => setIsOpen(false)}>
            <Close />
          </IconButton>
        </DrawerHeader>

        {DrawerList}
      </Drawer>
    </div>
  )
}

export { SidebarNotes }
