import { Typography } from '@mui/material'

import { House } from '#api/types'

import { GridModify } from '../../style'

type Props = {
  house: House
}

const HeaderRequestPdf = ({ house }: Props) => (
  <>
    <GridModify item xs={3}>
      Источник поступления:
    </GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={3}>
      <Typography align="center" fontWeight="bold">
        На объекте
      </Typography>
    </GridModify>
    <GridModify item xs={2}></GridModify>
    <GridModify item xs={4}>
      <Typography fontWeight="bold">Директору</Typography>
    </GridModify>

    <GridModify item xs={3}>
      Приоритет:
    </GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={3}>
      <Typography align="center" fontWeight="bold">
        Обычный
      </Typography>
    </GridModify>
    <GridModify item xs={2}></GridModify>
    <GridModify item xs={4}>
      <Typography fontWeight="bold">{house.developer}</Typography>
    </GridModify>

    <GridModify item xs={8}></GridModify>
    <GridModify item xs={4}>
      <Typography fontWeight="bold">{house.director}</Typography>
    </GridModify>

    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={7}></GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={5}>
      Принята с замечаниями
    </GridModify>
  </>
)

export { HeaderRequestPdf }
