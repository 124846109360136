import { Checkbox, CircularProgress } from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { AcceptRemark, MarkTheCompletion, useAcceptRemarkMutation, useMarkTheCompletionMutation } from '#api/note-api'
import { RemarksByApartment } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { getFullName } from '#utils'

import { RemarkContext, RemarksAllViewContext } from '../../contexts'
import { checkingExecutionRight } from '../../utils'
import { CheckboxMode } from '../../utils/config'

type Props = {
  mode: CheckboxMode
  remark?: RemarksByApartment
}

type DataByMode = {
  [key in CheckboxMode]: MarkTheCompletion | AcceptRemark
}

const RemarkCheckbox = ({ remark, mode }: Props) => {
  const { fetchActualRemarks } = useContextSafe(RemarksAllViewContext)
  const { paramsContext } = useContextSafe(RemarkContext)

  const user = useAppSelector((state) => state.userState.user)
  const users = useAppSelector((state) => state.userState.users) ?? []

  const checkedByMode = {
    [CheckboxMode.EXECUTION]: Boolean(Number(remark?.accepted)),
    [CheckboxMode.VERIFY]: Boolean(remark?.user_verify_id)
  }

  const [isChecked, setIsChecked] = useState(checkedByMode[mode])

  const methodByModeCheckbox = {
    [CheckboxMode.EXECUTION]: useMarkTheCompletionMutation,
    [CheckboxMode.VERIFY]: useAcceptRemarkMutation
  }

  const [method, { isLoading }] = methodByModeCheckbox[mode]()
  const isEmpty = remark?.accepted === undefined || !remark || !user

  if (isEmpty) {
    return <></>
  }

  const { id, date_acceptance: acceptanceDate } = remark
  const hasVerifyMode = mode === CheckboxMode.VERIFY
  const hasShowTime = mode === CheckboxMode.EXECUTION && acceptanceDate && isChecked

  const verifyUser = users.find(({ id: userId }) => userId === remark.user_verify_id)

  const handleChange = async () => {
    const { isApproval: isPermissionRight, message } = checkingExecutionRight(remark, user, mode)

    if (!isPermissionRight) {
      return toast.error(message, {
        position: 'top-right'
      })
    }

    const dataByMode: DataByMode = {
      [CheckboxMode.EXECUTION]: {
        is_mark: !isChecked,
        id
      },
      [CheckboxMode.VERIFY]: {
        user_verify_id: user.id,
        is_accept: !isChecked,
        id
      }
    }

    const requestByMethod = await method(dataByMode[mode] as AcceptRemark & MarkTheCompletion)

    if ('data' in requestByMethod) {
      fetchActualRemarks(remark, paramsContext)
      setIsChecked(!isChecked)
    } else {
      return toast.error('Ошибка при обновлении статуса', {
        position: 'top-right'
      })
    }
  }

  const Info = () => (
    <>
      {hasShowTime && (
        <p>
          <b>{new Date(acceptanceDate).toLocaleDateString()}</b>
        </p>
      )}

      {hasVerifyMode && (
        <p>
          <b>{getFullName(verifyUser)}</b>
        </p>
      )}

      <Checkbox onChange={handleChange} checked={isChecked} />
    </>
  )

  return !isLoading ? <Info /> : <CircularProgress />
}

export { RemarkCheckbox }
