import { FormControl, FormHelperText, MenuItem, Select, SelectProps, Typography } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Option = {
  slug: string
  id: string | number
}

type IFormSelectProps = {
  name: string
  label?: string
  options: Option[]
  mb?: number
} & SelectProps

export const FormSelect: FC<IFormSelectProps> = ({ mb = 2, name, label, options, ...otherProps }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <>
          <FormControl fullWidth sx={{ mb }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {label}
            </Typography>
            <Select
              {...field}
              fullWidth
              // disableUnderline
              // sx={{ borderRadius: '4px' }}
              error={!!errors[name]}
              {...otherProps}
            >
              {options.map(({ id, slug }) => (
                <MenuItem key={id} value={id}>
                  {slug}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors[name]}>{errors[name] ? errors[name].message : ''}</FormHelperText>
          </FormControl>
        </>
      )}
    />
  )
}

export default FormSelect
