import { zodResolver } from '@hookform/resolvers/zod'
import { Close, Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  Typography
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import ReactImageGallery from 'react-image-gallery'
import { toast } from 'react-toastify'
import { number, object, string, TypeOf } from 'zod'

import { useUpdateApartmentAcceptanceMutation } from '#api/download-api'
import { useRemovePhotoMutation } from '#api/note-api'
import { ApartmentPhoto, Roles } from '#api/types'
import { FormInput, FormSelect } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { type RemarksByApartment } from '#types/remark'
import { isAdmin, isSpecialist } from '#utils'

import { buildOptions } from '../../../notes/utils'
import { RemarkContext, RemarksAllViewContext } from '../../contexts'
import { useImages } from '../sidebar-remark/hooks'
import { getDefaultImages, getImages, getRemarkPeriod, getRemarkUserId } from './utils'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

type Props = {
  open: boolean
  setOpen: () => void
  remark: RemarksByApartment
  houseId: number
}

const CreateApartmentAcceptanceEditSchema = object({
  id: number().optional(),
  description: string().optional(),
  period: string().min(1).max(150).optional(),
  user_id: number().optional()
})

export type CreateApartmentAcceptanceEditInput = TypeOf<typeof CreateApartmentAcceptanceEditSchema>

type RemarkPhoto = {
  id: string
  original: string
  thumbnail: string
  houseId: number
}

const RemarkPhotoSidebar = ({ open, setOpen, remark, houseId }: Props) => {
  const [removePhoto] = useRemovePhotoMutation()
  const [updateApartmentAcceptanceMutation, { isSuccess, isLoading }] = useUpdateApartmentAcceptanceMutation()
  const { fetchActualRemarks } = useContextSafe(RemarksAllViewContext)
  const { paramsContext } = useContextSafe(RemarkContext)
  const { images, setImages, destroyImage, onDownloadFiles } = useImages()

  const user = useAppSelector((state) => state.userState.user)
  const isRightEdit = isAdmin(user) || isSpecialist(user)

  const [currentImages, setCurrentImages] = useState<RemarkPhoto[]>(getDefaultImages(remark, houseId))

  const methods = useForm<CreateApartmentAcceptanceEditInput>({
    resolver: zodResolver(CreateApartmentAcceptanceEditSchema),
    defaultValues: useMemo(
      () => ({
        id: remark.id,
        description: remark?.description ?? '',
        period: getRemarkPeriod(remark),
        user_id: getRemarkUserId(remark)
      }),
      [remark]
    )
  })

  const users = useAppSelector((state) => state.userState.users) ?? []

  const {
    handleSubmit
    // formState: { isSubmitSuccessful }
  } = methods

  const renderImagePhoto = (item: { original: string; id: string; houseId: number }) => {
    const onRemoveItem = async () => {
      const request = await removePhoto({
        id: Number(item.id),
        houseId
      })

      if ('data' in request) {
        setCurrentImages(currentImages.filter(({ id }) => id !== item.id))

        return fetchActualRemarks(remark, paramsContext)
      }

      toast.error('Ошибка при уделние фото', {
        position: 'top-right'
      })
    }

    return (
      <Box sx={{ p: 2 }}>
        <Card>
          <CardMedia component="img" image={item.original} />
          <CardActions>
            {isRightEdit && (
              <IconButton color="error" onClick={onRemoveItem}>
                <Delete />
              </IconButton>
            )}
          </CardActions>
        </Card>
      </Box>
    )
  }

  const onSubmitHandler: SubmitHandler<CreateApartmentAcceptanceEditInput> = async (
    values: CreateApartmentAcceptanceEditInput
  ) => {
    try {
      const data = new FormData()

      for (const key in images) {
        data.append('images[]', images[key]?.file)
      }

      for (const key in values) {
        data.append(key, String((values as any)[key] ?? ''))
      }

      const dataImages = await updateApartmentAcceptanceMutation(data)

      if ((dataImages as any)?.data?.images?.length > 0) {
        const newPhotoData: ApartmentPhoto[] = (dataImages as any).data.images
        setCurrentImages(getImages(newPhotoData, houseId))
      }
      setImages([])
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Замечание успешно измененно')
      fetchActualRemarks(remark, paramsContext)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const optionsUsers = buildOptions(
    users.filter((user) => {
      const isExpert = (user?.roles ?? []).map(({ name }) => name).includes(Roles.EXPERT)

      return isExpert
    })
  )

  const DrawerList = (
    <Box
      sx={{
        p: { xs: '1rem', sm: '2rem' }
      }}
    >
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Grid container spacing={2}>
          <Grid xs={8}>
            <ReactImageGallery
              autoPlay={false}
              showPlayButton={false}
              renderItem={renderImagePhoto}
              items={currentImages}
            />
          </Grid>
          <Grid xs={4}>
            <FormProvider {...methods}>
              <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
                <FormInput
                  disabled={!isRightEdit}
                  multiline={true}
                  rows={5}
                  mb={2}
                  name="description"
                  type="text"
                  label="Комментарий"
                />

                <FormSelect
                  disabled={!isRightEdit || Boolean(remark.accepted)}
                  mb={2}
                  name="user_id"
                  type="number"
                  label="Исполнитель *"
                  options={optionsUsers}
                />

                <FormInput disabled={!isRightEdit} mb={2} name="period" type="number" label="Период *" />
                {isRightEdit && (
                  <Button variant="contained" component="label" sx={{ mb: 2 }}>
                    Загрузить фото
                    <input
                      accept="image/*"
                      name="photos[]"
                      onChange={(e) => onDownloadFiles(e as SyntheticEvent)}
                      multiple
                      type="file"
                      hidden
                    />
                  </Button>
                )}

                {isRightEdit && (
                  <LoadingButton loading={isLoading} variant="contained" fullWidth disableElevation type="submit">
                    Редактировать
                  </LoadingButton>
                )}
              </Box>

              <ImageList sx={{ width: '100%', mt: 2 }}>
                {images.map(({ image }, idx) => (
                  <ImageListItem key={image}>
                    <img src={image} loading="lazy" />
                    <ImageListItemBar
                      actionIcon={
                        <IconButton color="error" onClick={() => destroyImage(idx)}>
                          <Delete />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </FormProvider>
          </Grid>
        </Grid>
      </List>
    </Box>
  )

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: { width: '95%' }
        }}
        open={open}
        role="presentation"
        anchor="right"
      >
        <DrawerHeader>
          <Grid sx={{ width: '100%', paddingLeft: '2rem' }}>
            <Typography variant="h5">Подробная информация</Typography>
          </Grid>
          <IconButton onClick={() => setOpen()}>
            <Close />
          </IconButton>
        </DrawerHeader>

        {DrawerList}
      </Drawer>
    </div>
  )
}

export { RemarkPhotoSidebar }
