import { Outlet } from 'react-router-dom'

import { Header } from './header'

const Layout = () => (
  <>
    <Header />
    <div style={{ marginTop: '80px' }}>
      <Outlet />
    </div>
  </>
)

export default Layout
