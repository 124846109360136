import AddHome from '@mui/icons-material/AddHome'
import { Button } from '@mui/material'

import { House } from '#api/types'
import { LayoutPage, Table } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { isAdmin, isSpecialist } from '#utils'

import { SidebarHouse, UpdateHouseSidebar } from './components'
import { HouseContext, HouseContextProvider, UpdateContextProvider } from './context'
import { useHouseDataTable } from './hooks'

const HousePage = () => {
  const { setIsOpen } = useContextSafe(HouseContext)
  const user = useAppSelector((state) => state.userState.user)
  const { houses, headCells } = useHouseDataTable()

  const isRightCreateHouse = isAdmin(user) || isSpecialist(user)

  const options = isRightCreateHouse
    ? {
        afterTitleElement: (
          <Button
            sx={{ maxWidth: '300px' }}
            startIcon={<AddHome />}
            variant="contained"
            onClick={() => setIsOpen(true)}
          >
            Создать дом
          </Button>
        )
      }
    : {}

  return (
    <LayoutPage title="Дома" {...options}>
      <>
        <Table
          isSelect={false}
          hasTableToolbar={false}
          tableName={`Дома (${houses?.length})`}
          propKey="id"
          defaultOrder="street"
          perPage={25}
          headCells={headCells}
          dataSource={houses as House[]}
        />

        <SidebarHouse />
        <UpdateHouseSidebar />
      </>
    </LayoutPage>
  )
}

const HousePageWithContext = () => (
  <UpdateContextProvider>
    <HouseContextProvider>
      <HousePage />
    </HouseContextProvider>
  </UpdateContextProvider>
)

export default HousePageWithContext
