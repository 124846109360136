import { Context, useContext } from 'react'

const useContextSafe = <StateType>(context: Context<StateType | null>): StateType => {
  const value = useContext(context)

  if (!value) {
    throw new Error('should not use uninitialized context')
  }

  return value
}

export { useContextSafe }
