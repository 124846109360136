import { Apartment } from '#api/types'

import { ColorRow } from '../../../../../config/constants'

type Props = {
  apartment: Apartment
  isRemarkDangerNoSpecialist: boolean
  isRemarkDanger: boolean
}

const getColor = ({ apartment, isRemarkDangerNoSpecialist, isRemarkDanger }: Props) => {
  const isRemark = isRemarkDangerNoSpecialist || isRemarkDanger

  if (apartment.document) {
    return [ColorRow.GREEN, ColorRow.YELLOW]
  }

  if (!apartment.document && isRemark) {
    return [ColorRow.RED]
  }

  if (!apartment.document && !isRemarkDangerNoSpecialist && !isRemarkDanger) {
    return [ColorRow.WHITE]
  }

  return [ColorRow.WHITE]
}

export { getColor }
