/*

Этот утилитный тип удаляет из объекта проперти, которые не строка и не число

type User = {
  name: string
  age: number
  isAdmin: boolean
  address: { city: string }
}

type P = StringAndNumberProps<User>

Результат:
{
  name: string
  age: number
}

*/

type StringAndNumberProps<T> = {
  [K in keyof T as T[K] extends string | number ? K : never]: T[K]
}

/*

Проперти для индексирования keyName может быть только строкой или числом

const users: User[] = []

const r1 = keyBy('name', users) // Ок. Результат Record<string, User>
const r2 = keyBy('age', users) // Ок. Результат Record<number, User>
const r3 = keyBy('isAdmin', users) // Error. Not string | number
const r4 = keyBy('address', users) // Error. Not string | number
const r5 = keyBy('kuku', users) // Error. Doesn't exit

*/

const keyBy = <T extends Record<K, string | number>, K extends keyof StringAndNumberProps<T>>(
  keyName: K,
  list: T[]
) => {
  const result = {} as Record<T[K], T>
  for (const element of list) {
    result[element[keyName]] = element
  }

  return result
}

export { keyBy }
