import { Roles, User } from '#api/types'

import { buildOptions } from '../../../utils'

const optionsUsers = (users: User[]) =>
  buildOptions(
    users.filter((user) => {
      const isExpert = (user?.roles ?? []).map(({ name }) => name).includes(Roles.EXPERT)

      return isExpert
    })
  )

export { optionsUsers }
