import { Close } from '@mui/icons-material'
import { Box, Grid, IconButton, List, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

import { useContextSafe } from '#hooks/use-context-safe'

import { RemarksAllViewContext } from '../../contexts'
import { RemarksTable } from '../remarks-table'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

type Props = {
  title: string
  houseId: number
}

const SidebarViewAllRemarks = ({ houseId, title }: Props) => {
  const {
    currentRemarks: remarks,
    setIsOpenAllRemarks,
    isOpenAllRemarks,
    currentType
  } = useContextSafe(RemarksAllViewContext)

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsOpenAllRemarks(newOpen)
  }

  const DrawerList = (
    <Box
      sx={{
        p: { xs: '1rem', sm: '2rem' }
      }}
    >
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <RemarksTable houseId={houseId} remarks={remarks} />
      </List>
    </Box>
  )

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: { width: '95%' }
        }}
        open={isOpenAllRemarks}
        role="presentation"
        anchor="right"
      >
        <DrawerHeader>
          <Grid sx={{ width: '100%', paddingLeft: '2rem' }}>
            <Typography variant="h5">
              Замечания. {title}. {currentType}
            </Typography>
          </Grid>
          <IconButton onClick={toggleDrawer(false)}>
            <Close />
          </IconButton>
        </DrawerHeader>

        {DrawerList}
      </Drawer>
    </div>
  )
}

export { SidebarViewAllRemarks }
