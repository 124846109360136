import { Box } from '@mui/material'

import { House, RemarksByApartment } from '#api/types'

import { ContactInfoRequestPdf, HeaderRequestPdf, MarkPerformanceRequestPdf, RemarksAcceptance } from './componentss'
import { GridWrapper } from './style'

type Props = {
  fullAddress: string
  number: string
  remarks: RemarksByApartment[]
  house: House
}

const ID_REMARK_REQUEST_CARD_PDF = 'remark-request-card-pdf'

const RemarkRequestCardPdf = ({ remarks, fullAddress, number, house }: Props) => (
  <Box id={ID_REMARK_REQUEST_CARD_PDF} sx={{ display: 'none' }}>
    <GridWrapper container spacing={2} sx={{ width: '100%', ml: 0, mt: 0 }}>
      <HeaderRequestPdf house={house} />

      <ContactInfoRequestPdf fullAddress={fullAddress} number={number} />

      <RemarksAcceptance remarks={remarks} />

      <MarkPerformanceRequestPdf />
    </GridWrapper>
  </Box>
)

export { RemarkRequestCardPdf, ID_REMARK_REQUEST_CARD_PDF }
