import { useAppSelector } from '#store'
import { isAdmin, isDirector, isExpert, isSpecialist } from '#utils'

const useRightCurrentUser = () => {
  const user = useAppSelector((state) => state.userState.user)

  const isAdminRight = isAdmin(user)
  const isSpecialistRight = isSpecialist(user)
  const isDirectorRight = isDirector(user)
  const isExpertRight = isExpert(user)

  const isPriorityRight = isAdminRight || isSpecialistRight || isExpertRight

  return {
    isAdminRight,
    isSpecialistRight,
    isDirectorRight,
    isExpertRight,
    isPriorityRight,
    user
  }
}

export { useRightCurrentUser }
