import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { MouseEvent, useState } from 'react'

import { useDestroyHouseMutation, useHousesMutation } from '#api/house-api'
import { House } from '#api/types'
import { PopperConfirmation } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'

import { UpdateContext } from '../../context'

type Props = {
  id: string
  house: House
}

const ActionsHouse = ({ id, house }: Props) => {
  const { setUpdateHouse } = useContextSafe(UpdateContext)

  const [destroyHouse] = useDestroyHouseMutation()
  const [getHouses] = useHousesMutation()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  const button = {
    accept: 'Удалить',
    decline: 'Отмена',
    acceptCallback: async () => {
      await destroyHouse(id)
      getHouses(null)
    }
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const updateHouse = () => {
    setUpdateHouse(house)
  }

  return (
    <>
      <IconButton color="primary" onClick={updateHouse}>
        <Edit />
      </IconButton>

      <IconButton color="error" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>

      <PopperConfirmation
        title="Вы точно хотите удалить дом"
        isOpen={open}
        setIsOpen={setOpen}
        anchorEl={anchorEl}
        button={button}
      />
    </>
  )
}

export { ActionsHouse }
