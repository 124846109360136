import { createApi } from '@reduxjs/toolkit/query/react'

import { UpdateInput } from '../../pages/users/components/sidebar/sidebar-user'
import { setRoles, setUsers } from '../features/userSlice'
import { withBaseQuery } from '../helpers/prepare-headers'
import { IGenericResponse, Role, User } from './types'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: withBaseQuery(`${BASE_URL}/api/user/`),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    users: builder.mutation<User[], null>({
      query() {
        return {
          url: 'users',
          credentials: 'include'
        }
      },
      transformResponse: (result: { users: User[] }) => result.users,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUsers(data))
        } catch (error) {}
      }
    }),
    infoLog: builder.mutation<string, null>({
      query() {
        return {
          url: 'log/info',
          credentials: 'include'
        }
      },
      transformResponse: (result: { data: string }) => result.data
    }),
    destroyUsersByIds: builder.mutation<number | null, { userIds: number[] }>({
      query(data) {
        return {
          url: 'destroyUsersByIds',
          credentials: 'include',
          method: 'POST',
          body: data
        }
      },
      transformResponse: (result: { data: number | null }) => result.data
    }),
    updateUser: builder.mutation<IGenericResponse, UpdateInput>({
      query(data) {
        return {
          url: 'update',
          method: 'POST',
          body: data
        }
      }
    }),
    roles: builder.mutation<Role[], null>({
      query() {
        return {
          url: 'roles',
          credentials: 'include'
        }
      },
      transformResponse: (result: { roles: Role[] }) => result.roles,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setRoles(data))
        } catch (error) {}
      }
    })
  })
})

const { useUsersMutation, useInfoLogMutation, useDestroyUsersByIdsMutation, useUpdateUserMutation, useRolesMutation } =
  userApi

export {
  useRolesMutation,
  useUsersMutation,
  useDestroyUsersByIdsMutation,
  useInfoLogMutation,
  useUpdateUserMutation,
  userApi
}
