import { createContext, ReactElement, useMemo, useState } from 'react'

import { RemarkByApartment, RemarksByApartment } from '#api/types'
import { useAppSelector } from '#store'

type ApartmentProps = {
  apartmentId: number
  houseId: number
}

type RemarkContextState = {
  remarksByApartment: RemarksByApartment[] | null
  setParamsContext: (apartmentProps: ApartmentProps) => void
  paramsContext: ApartmentProps
  currentState: RemarkByApartment | null
  setCurrentState: (currentState: RemarkByApartment | null) => void
}

type Props = {
  children: ReactElement
}

const RemarkContext = createContext<RemarkContextState | null>(null)

const RemarkContextProvider = ({ children }: Props) => {
  const [paramsContext, setParamsContext] = useState<ApartmentProps>({ apartmentId: 0, houseId: 0 })
  const [currentState, setCurrentState] = useState<RemarkByApartment | null>(null)

  const remarksByApartment = useAppSelector((state) => state.houseState.remarksByApartment)

  const value = useMemo(
    () => ({
      remarksByApartment,
      paramsContext,
      setParamsContext,
      currentState,
      setCurrentState
    }),
    [remarksByApartment, paramsContext, currentState]
  )

  return <RemarkContext.Provider value={value}>{children}</RemarkContext.Provider>
}

export { RemarkContext, RemarkContextProvider, type ApartmentProps }
