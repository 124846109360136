import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

const GridModify = styled(Grid)`
  border: 0.25px solid grey;
  padding: 5px !important;
  min-height: 30px;
  font-size: 11px;
`

const GridWrapper = styled(Grid)`
  border-top: 0.25px solid grey;
  border-bottom: 0.25px solid grey;
  font-size: 11px;
`

const GridBase = styled(Grid)`
  min-height: 30px;
  font-size: 11px;
`

export { GridWrapper, GridModify, GridBase }
