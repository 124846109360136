import { RemarkByApartment } from '#api/types'
import { declension, getDayDiff } from '#utils'

import { DeclensionOfDaysEnd, TheWorkIsOverdue } from '../../utils'

type Props = {
  item: RemarkByApartment
}

const RemarkCompletionDate = ({ item }: Props) => {
  const { end_time: endTime } = item

  if (!endTime) {
    return <></>
  }

  const { user_verify_id: userVerifyId } = item
  const time = new Date(endTime).toLocaleDateString()

  if (userVerifyId) {
    return <b>{time}</b>
  }

  const currentDate = new Date()
  const dayToTheEnd = Math.round(getDayDiff(currentDate, new Date(endTime)))

  return (
    <>
      {dayToTheEnd > 0 ? (
        <p>
          Осталось: {dayToTheEnd} {declension(dayToTheEnd, DeclensionOfDaysEnd)}
        </p>
      ) : (
        TheWorkIsOverdue
      )}
      <p>
        Выполнить до <b>{time}</b>
      </p>
    </>
  )
}

export { RemarkCompletionDate }
