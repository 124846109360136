import { zodResolver } from '@hookform/resolvers/zod'
import { Close, Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Switch,
  Typography
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { number, object, string, TypeOf } from 'zod'

import { useCreateMutation } from '#api/download-api'
import { useHouseMutation, useRemarksByApartmentMutation } from '#api/house-api'
import { useNoteByTypeIdMutation } from '#api/note-api'
import { Apartment, RemarksByApartment, Roles } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'

import FormInput from '../../../../components/FormInput'
import FormSelect from '../../../../components/FormSelect'
import { buildOptions } from '../../../notes/utils'
import { RemarkContext, RemarkNewContext } from '../../contexts'
import { useImages } from './hooks'

export enum Mode {
  EDIT = 'edit',
  CREATE = 'create'
}

type Props = {
  mode?: Mode
  apartment: Apartment
  remarksByApartment: RemarksByApartment[]
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

const createRemarkSchema = object({
  house_id: number(),
  apartment_id: number(),
  remark_id: number(),
  period: string().min(1).max(150).optional(),
  description: string().optional(),
  user_id: number()
})

export type CreateRemarkInput = TypeOf<typeof createRemarkSchema>

const SidebarRemark = ({ mode = Mode.EDIT, apartment, remarksByApartment }: Props) => {
  const [getNoteByTypeId] = useNoteByTypeIdMutation()
  const [getRemarksByApartment] = useRemarksByApartmentMutation()
  const [createNewRemark, { isLoading, isSuccess }] = useCreateMutation()
  const [getHouse] = useHouseMutation()
  const { paramsContext } = useContextSafe(RemarkContext)
  const { detailedRemarkByApartment, setDetailedRemarkByApartment } = useContextSafe(RemarkNewContext)

  const { images, setImages, destroyImage, onDownloadFiles } = useImages()
  const [checkedUser, setCheckedUser] = useState(false)

  const users = useAppSelector((state) => state.userState.users) ?? []
  const type = useAppSelector((state) => state.noteState.type)
  const remarks = type?.remarks ?? []
  const { typeId } = detailedRemarkByApartment ?? {}

  useEffect(() => {
    if (isSuccess) {
      toast.success('Замечание успешно добавленно')
      getRemarksByApartment(paramsContext)
      setImages([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (typeId) {
      getNoteByTypeId(typeId)
    }
  }, [detailedRemarkByApartment])

  const toggleDrawer = () => () => {
    setDetailedRemarkByApartment(null)
  }

  const methods = useForm<CreateRemarkInput>({
    resolver: zodResolver(createRemarkSchema),
    defaultValues: useMemo(
      () => ({
        house_id: apartment.house_id,
        apartment_id: apartment.id
      }),
      [apartment]
    )
  })

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'remark_id') {
        const remark = remarks.find(({ id }) => id === value.remark_id)

        if (remark?.user_id) {
          setValue('user_id', remark.user_id)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, users, remarks])

  const remarksOptions = remarks.map((item) => ({
    id: item.id,
    slug: item.name
  }))

  const onSubmitHandler: SubmitHandler<CreateRemarkInput> = async (values: any) => {
    const data = new FormData()

    for (const key in images) {
      data.append('images[]', images[key]?.file)
    }

    for (const key in values) {
      data.append(key, values[key])
    }

    if (detailedRemarkByApartment?.typeId) {
      data.append('apartments_acceptance_types_id', String(detailedRemarkByApartment.typeId))
    }

    data.append('count_remarks', String(remarksByApartment.length))

    await createNewRemark(data)

    getHouse(apartment.house_id)
  }

  const optionsUsers = buildOptions(
    users.filter((user) => {
      const isExpert = (user?.roles ?? []).map(({ name }) => name).includes(Roles.EXPERT)

      if (checkedUser && isExpert) {
        return true
      }

      const userIds = (user?.houses ?? []).map(({ id }) => id)

      return userIds.includes(apartment.house_id) && isExpert
    })
  )

  const DrawerList = (
    <Box sx={{ width: '600px' }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{
            p: { xs: '1rem', sm: '2rem' }
          }}
        >
          {mode === Mode.EDIT && <FormInput name="date" type="date" label="Дата проверки" />}

          <FormSelect name="remark_id" type="number" label="Замечание *" options={remarksOptions} />

          <FormControlLabel
            control={<Switch checked={checkedUser} onChange={() => setCheckedUser(!checkedUser)} />}
            label="Все исполнители"
          />

          <FormSelect name="user_id" type="number" label="Исполнитель" options={optionsUsers} />

          <FormInput multiline={true} rows={4} name="description" type="text" label="Комментарий" />

          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Загрузить фото
            <input
              accept="image/*"
              name="photos[]"
              onChange={(e) => onDownloadFiles(e as SyntheticEvent)}
              multiple
              type="file"
              hidden
            />
          </Button>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            disableElevation
            type="submit"
          >
            Создать
          </LoadingButton>

          <ImageList sx={{ width: '100%' }}>
            {images.map(({ image }, idx) => (
              <ImageListItem key={image}>
                <img src={image} loading="lazy" />
                <ImageListItemBar
                  actionIcon={
                    <IconButton color="error" onClick={() => destroyImage(idx)}>
                      <Delete />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </FormProvider>
    </Box>
  )

  return (
    <div>
      <Drawer open={Boolean(detailedRemarkByApartment)} role="presentation" anchor="right">
        <DrawerHeader>
          <Grid sx={{ width: '100%', paddingLeft: '2rem' }}>
            <Typography variant="h5">{type?.slug}</Typography>
          </Grid>
          <IconButton onClick={toggleDrawer()}>
            <Close />
          </IconButton>
        </DrawerHeader>

        {DrawerList}
      </Drawer>
    </div>
  )
}

export { SidebarRemark }
