import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton as _LoadingButton } from '@mui/lab'
import { Box, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, string, TypeOf } from 'zod'

import FormInput from '../components/FormInput'
import { useRegisterUserMutation } from '../redux/api/auth-api'

const LoadingButton = styled(_LoadingButton)`
  //padding: 0.6rem 0;
  //background-color: #f9d13e;
  //color: #2363eb;
  //font-weight: 500;
  //
  //&:hover {
  //  background-color: #ebc22c;
  //  transform: translateY(-2px);
  //}
`

const LinkItem = styled(Link)``

const registerSchema = object({
  email: string().min(1, 'Email address is required').email('Email Address is invalid'),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  password_confirmation: string().min(1, 'Please confirm your password')
}).refine((data) => data.password === data.password_confirmation, {
  path: ['password_confirm'],
  message: 'Passwords do not match'
})

export type RegisterInput = TypeOf<typeof registerSchema>

const RegisterPage = () => {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema)
  })

  // 👇 Calling the Register Mutation
  const [registerUser, { isLoading, isSuccess, error, isError }] = useRegisterUserMutation()

  const navigate = useNavigate()

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    if (isSuccess) {
      toast.success('User registered successfully')
      navigate('/verifyemail')
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    // 👇 Executing the RegisterUser Mutation
    registerUser(values)
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#2363eb'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography
          textAlign="center"
          component="h1"
          sx={{
            color: '#f9d13e',
            fontSize: { xs: '2rem', md: '3rem' },
            fontWeight: 600,
            mb: 2,
            letterSpacing: 1
          }}
        >
          Welcome to CodevoWeb!
        </Typography>
        <Typography component="h2" sx={{ color: '#e5e7eb', mb: 2 }}>
          Sign Up To Get Started!
        </Typography>

        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            maxWidth="27rem"
            width="100%"
            sx={{
              backgroundColor: '#e5e7eb',
              p: { xs: '1rem', sm: '2rem' },
              borderRadius: 2
            }}
          >
            <FormInput name="email" label="Email Address" type="email" />
            <FormInput name="password" label="Password" type="password" />
            <FormInput name="password_confirm" label="Confirm Password" type="password" />
            <Typography sx={{ fontSize: '0.9rem', mb: '1rem' }}>
              Already have an account? <LinkItem to="/login">Login Here</LinkItem>
            </Typography>

            <LoadingButton
              variant="contained"
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              Sign Up
            </LoadingButton>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  )
}

export default RegisterPage
