export enum Roles {
  ADMIN = 'admin',
  EXPERT = 'expert',
  DIRECTOR = 'director',
  SPECIALIST = 'specialist',
  DOCUMENTARIAN = 'documentarian'
}

export interface IUser {
  email: string
  role: Roles
  id: number
}

export type Role = {
  id: number
  name: string
  slug: string
}

export type Profile = {
  first_name: string
  last_name: string
  father_name: string
  position: string
  phone: string
}

export type User = {
  id: number
  name: string
  email: string
  profile?: Profile
  roles?: Role[]
  houses?: House[]
}

export interface IGenericResponse {
  status: string
  message: string
}

export enum ApartmentType {
  Apartment = 1,
  Office = 2,
  Storage = 3
}

export type Apartment = {
  id: number
  rooms: number | null
  description: string | null
  number: number
  house_id: number
  extent: string
  document: string
  type: ApartmentType
}

export type House = {
  id: number
  street: string
  developer: string
  director: string
  number: number
  drop: number
  created_at: Date
  updated_at: Date
  apartments: Apartment[]
}

export type Note = {
  id: number
  category_id?: number
  period: number
  name: string
  user_id: number
}

export type NotesByType = {
  id: number
  slug: string
  remarks: Note[]
}

export type ApartmentPhoto = {
  id: string
  image: string
  original: string
}

export type RemarksByApartment = {
  accepted: boolean | number
  apartment_id: number
  apartments_acceptance_types_id: number
  created_at: Date
  updated_at: Date
  date_acceptance: Date
  date_verify: Date
  description: null | string
  photos: ApartmentPhoto[]
  id: number
  period: null | number
  remark_id: number
  user_id: number
  user_verify_id: number
  user_specialist_id: number
  apartment?: Apartment
  specialist?: User
  remark?: Note
  user?: User
}

export type UnionRemarksByApartment = RemarksByApartment & { endTime: number }

export type RemarkByApartment = {
  typesOfWork?: string
  typeId?: number
  remarks?: RemarksByApartment[]
  relevantRemark: UnionRemarksByApartment
  created_at: Date
  end_time: Date
  remark: string
  user_verify_id?: number
}

export type RemarksByType = {
  [key: number]: RemarksByApartment[]
}

// @ts-ignore
export type ApartmentsAcceptanceType = {
  id: number
  slug: string
  name: string
}
