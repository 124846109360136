import { ApartmentPhoto, RemarksByApartment } from '#api/types'

const getDefaultImages = (remark: RemarksByApartment, houseId: number) =>
  remark?.photos.map(({ original, id }) => ({
    id,
    original,
    thumbnail: original,
    houseId
  })) ?? []

const getImages = (photos: ApartmentPhoto[], houseId: number) =>
  photos.map(({ original, id }) => ({
    id,
    original,
    thumbnail: original,
    houseId
  })) ?? []

export { getDefaultImages, getImages }
