import { createApi } from '@reduxjs/toolkit/query/react'

import { CreateHouseInput, UpdateHouseInput } from '../../pages/house/types'
import {
  setAcceptanceType,
  setHouse,
  setHouses,
  setRemarksApartmentsByHome,
  setRemarksByApartment
} from '../features/houseSlice'
import { withBaseQuery } from '../helpers/prepare-headers'
import { ApartmentsAcceptanceType, House, IGenericResponse, RemarksByApartment } from './types'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

const houseApi = createApi({
  reducerPath: 'houseApi',
  baseQuery: withBaseQuery(`${BASE_URL}/api/house/`),
  tagTypes: ['House'],
  endpoints: (builder) => ({
    createHouse: builder.mutation<IGenericResponse, CreateHouseInput>({
      query(data) {
        return {
          url: 'create',
          method: 'POST',
          body: data
        }
      }
    }),
    updateHouse: builder.mutation<IGenericResponse, UpdateHouseInput>({
      query(data) {
        return {
          url: 'update',
          method: 'PUT',
          body: data
        }
      }
    }),
    destroyHouse: builder.mutation<boolean, string>({
      query(id) {
        return {
          url: `destroy/${id}`,
          credentials: 'include',
          method: 'DELETE'
        }
      }
    }),
    houses: builder.mutation<House[], null>({
      query() {
        return {
          url: 'houses',
          credentials: 'include'
        }
      },
      transformResponse: (result: { houses: House[] }) => result.houses,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setHouses(data))
        } catch (error) {}
      }
    }),
    deleteDocument: builder.mutation<boolean, { house_id: number; apartment_id: number }>({
      query(data) {
        return {
          url: `delete/document`,
          credentials: 'include',
          body: data,
          method: 'DELETE'
        }
      }
    }),
    remarksByApartment: builder.mutation<RemarksByApartment[], { houseId: number; apartmentId: number }>({
      query({ apartmentId, houseId }) {
        return {
          url: `remarks/apartment/${apartmentId}/${houseId}`,
          credentials: 'include'
        }
      },
      transformResponse: (result: { data: RemarksByApartment[] }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setRemarksByApartment(data))
        } catch (error) {}
      }
    }),
    house: builder.mutation<House, number>({
      query(houseId) {
        return {
          url: `apartments/${houseId}`,
          credentials: 'include'
        }
      },
      transformResponse: (result: { house: House }) => result.house,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setHouse(data))
        } catch (error) {}
      }
    }),
    apartmentsInfoByHome: builder.mutation<RemarksByApartment[], number>({
      query(houseId) {
        return {
          url: `/apartments/info/${houseId}`,
          credentials: 'include'
        }
      },
      transformResponse: (result: { data: RemarksByApartment[] }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setRemarksApartmentsByHome(data))
        } catch (error) {}
      }
    }),
    types: builder.mutation<ApartmentsAcceptanceType[], null>({
      query() {
        return {
          url: 'types',
          credentials: 'include'
        }
      },
      transformResponse: (result: { types: ApartmentsAcceptanceType[] }) => result.types,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setAcceptanceType(data))
        } catch (error) {}
      }
    })
  })
})

const {
  useUpdateHouseMutation,
  useHousesMutation,
  useDestroyHouseMutation,
  useCreateHouseMutation,
  useTypesMutation,
  useRemarksByApartmentMutation,
  useApartmentsInfoByHomeMutation,
  useHouseMutation,
  useDeleteDocumentMutation
} = houseApi

export {
  useHousesMutation,
  useDestroyHouseMutation,
  useUpdateHouseMutation,
  useCreateHouseMutation,
  useTypesMutation,
  useRemarksByApartmentMutation,
  useApartmentsInfoByHomeMutation,
  useHouseMutation,
  useDeleteDocumentMutation,
  houseApi
}
