import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Button from '@mui/material/Button'

import { LayoutPage } from '#components'

import { SidebarUser, TableUser } from './components'
import { useMethods } from './hooks'

const UsersPage = () => {
  const {
    open,
    selected,
    selectedUser,
    setOpen,
    destroyUsers,
    openNewSidebar,
    selectedByUser,
    setSelected,
    setSelectedUser
  } = useMethods()

  return (
    <LayoutPage
      title="Пользователи"
      afterTitleElement={
        <Button sx={{ maxWidth: '300px' }} startIcon={<PersonAddIcon />} variant="contained" onClick={openNewSidebar}>
          Создать пользователя
        </Button>
      }
    >
      <>
        <TableUser
          destroyUsers={destroyUsers}
          selectedByUser={selectedByUser}
          selected={selected}
          setSelected={setSelected}
        />

        <SidebarUser open={open} setOpen={setOpen} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
      </>
    </LayoutPage>
  )
}

export default UsersPage
