import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useDestroyNoteMutation, useUpdateNoteMutation } from '#api/note-api'
import { Note } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'

import { NoteContext } from '../../../context'
import { UpdateNoteInput, updateNoteSchema } from '../types'

type Props = {
  remark: Note
  typeId: number
}

const useMethods = ({ remark, typeId }: Props) => {
  const { getNotesByType } = useContextSafe(NoteContext)

  const [updateNote, { isLoading, isSuccess }] = useUpdateNoteMutation()

  const [destroyNote, { isLoading: isLoadingDestroy, isSuccess: isSuccessDestroy }] = useDestroyNoteMutation()

  const methods = useForm<UpdateNoteInput>({
    resolver: zodResolver(updateNoteSchema),
    defaultValues: useMemo(
      () =>
        ({
          id: remark.id,
          category_id: typeId,
          period: String(remark.period),
          name: remark.name,
          user_id: remark.user_id
        }) as UpdateNoteInput,
      [remark.id, remark.name, remark.period, remark.user_id, typeId]
    )
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    if (isSuccessDestroy) {
      toast.success('Замечание успешно удаленно')
    }
  }, [isLoadingDestroy])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Замечание успешно обновленно')
    }
  }, [isLoading])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<UpdateNoteInput> = async (values) => {
    await updateNote(values as UpdateNoteInput)
    getNotesByType(null)
  }

  const onDestroyNote = async () => {
    await destroyNote(remark.id)
    getNotesByType(null)
  }

  return {
    onDestroyNote,
    onSubmitHandler,
    handleSubmit,
    methods
  }
}

export { useMethods }
