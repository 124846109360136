import { ColorRow } from '../../config/constants'
import { RemarksByApartment } from '../../redux/api/types'
import { getDayDiff, getEndDate } from '../time'

type BackgroundStyle = {
  backgroundColor: ColorRow
}

const getColorRow = (relevantRemark: RemarksByApartment, defaultColor = ColorRow.WHITE) => {
  const { created_at, period, remark = null, user_verify_id: userVerifyId } = relevantRemark
  const completionDay = period ?? remark?.period ?? 0
  let color = defaultColor

  if (userVerifyId) {
    return {
      backgroundColor: ColorRow.GREEN
    }
  }

  const currentDate = new Date()
  const endDate = getEndDate(created_at, completionDay)

  if (currentDate.getTime() > endDate.getTime()) {
    return {
      backgroundColor: ColorRow.RED
    }
  }

  const dayToTheEnd = getDayDiff(currentDate, endDate)

  if (dayToTheEnd > 0) {
    color = ColorRow.YELLOW
  }

  return {
    backgroundColor: color
  }
}

export { type BackgroundStyle, getColorRow }
