import { useContextSafe } from '#hooks/use-context-safe'

import { NoteContext } from '../../context'
import { AccordionProperty } from '../accordion-property'

const AccordionNotes = () => {
  const { notesByType } = useContextSafe(NoteContext)

  return (
    <>
      {notesByType.map((note, id) => (
        <AccordionProperty key={`key-${id}-${note.id}-${note.slug}-${note.remarks.length}`} note={note} />
      ))}
    </>
  )
}

export { AccordionNotes }
