import { Breadcrumbs, Typography } from '@mui/material'
import { Fragment, ReactElement } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  breadcrumbs: BreadcrumbsType[]
}

type BreadcrumbsType = {
  to?: string
  text: ReactElement | string
}

const Breadcrumb = ({ breadcrumbs }: Props) => (
  <Breadcrumbs aria-label="breadcrumb">
    {breadcrumbs.map(({ to = null, text }, idx) => (
      <Fragment key={idx}>
        {to ? (
          <Link to={to}>{text}</Link>
        ) : (
          <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
            {text}
          </Typography>
        )}
      </Fragment>
    ))}
  </Breadcrumbs>
)

export { type BreadcrumbsType, Breadcrumb }
