import { Add } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

import { RemarksByApartment } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { isAdmin, isSpecialist } from '#utils'

import { HeadCell } from '../../../components/table/type'
import { RemarkCheckbox, RemarkCompletionDate, RemarkName, RemarkUserName } from '../components'
import { RemarkNewContext } from '../contexts'
import { Style } from '../utils'
import { CheckboxMode } from '../utils/config'
import { type RemarkByApartment } from './use-data-table'

type Props = {
  setCurrentRemark?: (remark: RemarksByApartment) => void
  hasDetailTable?: boolean
}

const defaultMethod = () => null

const useCell = ({ setCurrentRemark = defaultMethod, hasDetailTable = false }: Props = {}) => {
  const { setDetailedRemarkByApartment } = useContextSafe(RemarkNewContext)
  const user = useAppSelector((state) => state.userState.user)
  const isAdminRight = isAdmin(user)
  const isSpecialistRight = isSpecialist(user)

  const hasCreateRemark = isSpecialistRight || isAdminRight

  const firstColumn: HeadCell<RemarkByApartment> = !hasDetailTable
    ? {
        id: 'typesOfWork',
        numeric: false,
        disablePadding: false,
        isSort: true,
        label: 'Основные виды работ',
        style: { ...Style, minWidth: '200px' },
        align: 'left',
        render: (typesOfWork, remarkByApartment) => (
          <Grid container direction="column" alignItems="start">
            {typesOfWork}
            {hasCreateRemark && (
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
                onClick={() => setDetailedRemarkByApartment(remarkByApartment)}
                aria-label="add"
              >
                <Add />
              </Button>
            )}
          </Grid>
        )
      }
    : {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'Id'
      }

  const headCells: HeadCell<RemarkByApartment>[] = [
    firstColumn,
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Дата создания',
      align: 'left',
      style: { ...Style, minWidth: '200px' }
    },
    {
      id: 'user_specialist_id',
      numeric: false,
      disablePadding: false,
      label: 'Специалист',
      align: 'left',
      style: { ...Style, minWidth: '200px' },
      render: (specialistId: string, item) => <RemarkUserName isSpecialist={true} userId={specialistId} item={item} />
    },
    {
      id: 'remark',
      numeric: false,
      disablePadding: false,
      label: 'Замечания',
      align: 'left',
      style: { ...Style, minWidth: '300px' },
      render: (remarkName: string, item) => {
        const props = {
          remarkName,
          item,
          hasDetailTable,
          setCurrentRemark
        }

        return <RemarkName {...props} />
      }
    },
    {
      id: 'end_time',
      numeric: false,
      disablePadding: false,
      label: 'Срок выполнения',
      align: 'left',
      style: { ...Style, minWidth: '250px' },
      render: (_, item) => <RemarkCompletionDate item={item} />
    },
    {
      id: 'executor',
      numeric: false,
      disablePadding: false,
      label: 'Исполнитель',
      style: { ...Style, minWidth: '175px' },
      align: 'left',
      render: (executorId: string, item) => <RemarkUserName userId={executorId} item={item} />
    },
    {
      id: 'is_mark',
      numeric: false,
      disablePadding: false,
      label: 'Отметка о выполнении',
      style: Style,
      align: 'center',
      render: (_, { relevantRemark }) => <RemarkCheckbox mode={CheckboxMode.EXECUTION} remark={relevantRemark} />
    },
    {
      id: 'date_verify',
      numeric: false,
      disablePadding: false,
      label: 'Дата проверки',
      align: 'center',
      style: Style,
      render: (dateVerify) => {
        if (!dateVerify) {
          return ''
        }

        return <b>{new Date(dateVerify).toLocaleDateString()}</b>
      }
    },
    {
      id: 'checked',
      numeric: false,
      disablePadding: false,
      label: 'Проверил',
      align: 'center',
      style: Style,
      render: (_, { relevantRemark }) => <RemarkCheckbox mode={CheckboxMode.VERIFY} remark={relevantRemark} />
    }
  ]

  return {
    headCells
  }
}

export { useCell }
