import { Container } from '@mui/material'
import { styled } from '@mui/material/styles'

const LoginContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e5e1e2;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./bg_main.jpg');
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.55;
  }
`

const StyleImage = {
  borderRadius: '50%',
  marginBottom: '20px'
}

const StyleBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 22
}

const StyleTypography = {
  color: '#e5e7eb',
  mb: 2
}

const StyleBoxFormProvider = {
  backgroundColor: '#e5e7eb',
  p: { xs: '1rem', sm: '2rem' },
  borderRadius: 2
}

export { LoginContainer, StyleBox, StyleImage, StyleTypography, StyleBoxFormProvider }
