import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useLoginUserMutation } from '#api/auth-api'

import { LoginInput, loginSchema } from '../types'

const useLogin = () => {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema)
    // defaultValues: {
    //   email: 'normis008@mail.ru',
    //   password: '123456'
    // }
  })
  const [_, setCookies] = useCookies(['logged_in'])
  const [loginUser, { isLoading, isSuccess }] = useLoginUserMutation()

  const navigate = useNavigate()
  const location = useLocation()

  const from = ((location.state as any)?.from.pathname as string) || '/profile'

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    if (isSuccess) {
      toast.success('Подготавливаем актуальные данные, подождите чуть-чуть')
      setCookies('logged_in', isSuccess)
      navigate(from)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {
    await loginUser(values)
  }

  return {
    methods,
    handleSubmit,
    onSubmitHandler,
    isLoading
  }
}

export { useLogin }
