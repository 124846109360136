import { RemarksByApartment } from '../../../../../redux/api/types'

const getRemarkPeriod = (remark: RemarksByApartment) => {
  const { period } = remark

  if (period) {
    return String(period)
  }

  const { remark: defaultRemark } = remark

  return String(defaultRemark?.period)
}

const getRemarkUserId = (remark: RemarksByApartment) => {
  const { user_id: userId } = remark

  if (userId) {
    return userId
  }

  const { remark: defaultRemark } = remark

  return defaultRemark?.user_id
}

export { getRemarkPeriod, getRemarkUserId }
