import { FormControl, FormHelperText, Input, InputProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const FInput = styled(Input)`
  border: 1px solid grey;
  padding: 4.5px 11px;
`

type IFormInputProps = {
  name: string
  label?: string
  mb?: number
} & InputProps

export const FormInput: FC<IFormInputProps> = ({ mb, name, label, ...otherProps }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <>
          <FormControl variant="filled" fullWidth sx={{ mb }}>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
              {label}
            </Typography>
            <FInput {...field} sx={{ borderRadius: '4px' }} error={!!errors[name]} {...otherProps} />
            <FormHelperText error={!!errors[name]}>{errors[name] ? errors[name].message : ''}</FormHelperText>
          </FormControl>
        </>
      )}
    />
  )
}

export default FormInput
