import { IUser, Roles } from '#api/types'

const isDirector = (user: IUser | null) => {
  if (!user) {
    return false
  }

  return user.role === Roles.DIRECTOR
}

export { isDirector }
