const Style = {
  whiteSpace: 'nowrap',
  wordWrap: 'break-word'
} as const

const NotFoundRemarks = 'Нет замечаний'
const TheWorkIsOverdue = 'Работа просрочена'
const DeclensionOfRemarks = ['замечание', 'замечания', 'замечаний']
const DeclensionOfDaysEnd = ['день', 'дня', 'дней']

export { Style, DeclensionOfRemarks, DeclensionOfDaysEnd, TheWorkIsOverdue, NotFoundRemarks }
