import { PhotoAlbum } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Link } from '@mui/material'
import { toast } from 'react-toastify'

import { useDestroyApartmentAcceptanceMutation } from '#api/note-api'
import { RemarkByApartment, RemarksByApartment } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { declension, isAdmin } from '#utils'

import { RemarkContext, RemarksAllViewContext } from '../../contexts'
import { DeclensionOfRemarks, NotFoundRemarks } from '../../utils'

type Props = {
  hasDetailTable: boolean
  remarkName: string
  item: RemarkByApartment
  setCurrentRemark: (remark: RemarksByApartment) => void
}

const RemarkName = ({ remarkName, item, hasDetailTable, setCurrentRemark }: Props) => {
  const [destroyApartmentAcceptance] = useDestroyApartmentAcceptanceMutation()

  const { setCurrentRemarks, setIsOpenAllRemarks, setCurrentType, fetchActualRemarks } =
    useContextSafe(RemarksAllViewContext)
  const { paramsContext } = useContextSafe(RemarkContext)
  const { houseId } = paramsContext

  const user = useAppSelector((state) => state.userState.user)
  const isAdminRight = isAdmin(user)

  const { remarks = [], relevantRemark } = item
  const countNotVisibleRemark = remarks?.length - 1
  const hasLink = countNotVisibleRemark >= 1

  const textLink = hasLink
    ? `еще ${countNotVisibleRemark} ${declension(countNotVisibleRemark, DeclensionOfRemarks)}`
    : 'Открыть'

  if (!remarkName) {
    return <>{NotFoundRemarks}</>
  }

  const idRemark = `№${relevantRemark.id}`

  const onShowRemarks = () => {
    setCurrentRemarks(remarks)
    setCurrentType(item.typesOfWork ?? '')
    setIsOpenAllRemarks(true)
  }

  const onDestroy = async () => {
    const request = await destroyApartmentAcceptance({
      id: relevantRemark.id,
      houseId
    })

    if ('data' in request) {
      fetchActualRemarks(relevantRemark, paramsContext)
    } else {
      return toast.error('Что-то пошло не так повторите запрос позднее', {
        position: 'top-right'
      })
    }
  }

  return (
    <>
      {/*<pre>{JSON.stringify(item, null, 2)}</pre>*/}
      <p>
        <b>{!hasDetailTable && idRemark}</b> {remarkName}
      </p>
      {!hasDetailTable && <Link onClick={onShowRemarks}>{textLink}</Link>}

      {hasDetailTable && (
        <>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setCurrentRemark(relevantRemark)}
            aria-label="add"
          >
            <PhotoAlbum sx={{ mr: 0.5 }} />
            Подробнее
          </Button>

          {isAdminRight && (
            <Button
              onClick={() => onDestroy()}
              type="submit"
              color="error"
              variant="outlined"
              size="small"
              aria-label="delete"
              sx={{ ml: 1 }}
            >
              <DeleteIcon />
            </Button>
          )}
        </>
      )}
    </>
  )
}

export { RemarkName }
