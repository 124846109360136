// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Grid } from '@mui/material'

import { IUser, RemarksByApartment, RemarksByType } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

type Props = {
  groupByApartment: RemarksByType
  id: number
  typeId?: number | null
}

const filterByType = (
  remark: RemarksByApartment,
  typeId: number | null,
  isExpertRight: boolean,
  user: IUser | null
) => {
  if (typeId === null) {
    return true
  }

  if (isExpertRight) {
    return remark.apartments_acceptance_types_id === typeId && remark.user_id === user?.id
  }

  return remark.apartments_acceptance_types_id === typeId
}

const filterAcceptance = (remark: RemarksByApartment, isExpertRight: boolean, user: IUser | null) => {
  if (isExpertRight) {
    return remark.user_id === user?.id && remark.user_verify_id === null && !remark.accepted
  }

  return remark.user_verify_id === null && !remark.accepted
}

const filterByExpert = (remark: RemarksByApartment, typeId: number | null, user: IUser | null) => {
  if (typeId === null) {
    return !remark.user_verify_id && remark?.accepted && remark.user_specialist_id === user?.id
  }

  return (
    remark.apartments_acceptance_types_id === typeId &&
    !remark.user_verify_id &&
    remark?.accepted &&
    remark.user_specialist_id === user?.id
  )
}

const CountRemark = ({ groupByApartment, id, typeId = null }: Props) => {
  const remarks = groupByApartment[Number(id)] ?? []
  const { isExpertRight, isPriorityRight, user } = useRightCurrentUser()

  if (!isPriorityRight) {
    return null
  }

  const countAllRemark = isExpertRight
    ? remarks.filter(
        (remark) => filterAcceptance(remark, isExpertRight, user) && filterByType(remark, typeId, isExpertRight, user)
      ).length
    : remarks.filter((remark) => filterByExpert(remark, typeId, user)).length

  if (countAllRemark === 0) {
    return null
  }

  return (
    <Grid
      sx={{
        ml: 1,
        color: 'darkred',
        display: 'flex',
        backgroundColor: 'rgba(255,255,255, 0.7)',
        borderRadius: '5px',
        padding: '0 5px',
        border: '1px solid darkred'
      }}
      alignItems="center"
    >
      {/*<ErrorOutlineIcon color="error" />*/}
      {countAllRemark}
    </Grid>
  )
}

export { CountRemark }
