import { createApi } from '@reduxjs/toolkit/query/react'

import { prepareHeadersFormDataToken, withBaseQuery } from '../helpers/prepare-headers'
import { IGenericResponse } from './types'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

export const downloadApi = createApi({
  reducerPath: 'downloadApi',
  baseQuery: withBaseQuery(`${BASE_URL}/api/remark/`, prepareHeadersFormDataToken),
  endpoints: (builder) => ({
    create: builder.mutation<IGenericResponse, FormData>({
      query(data) {
        return {
          url: 'newRemark',
          method: 'POST',
          body: data
        }
      }
    }),
    updateApartmentAcceptance: builder.mutation<IGenericResponse, FormData>({
      query(data) {
        return {
          url: '/apartment/acceptance',
          credentials: 'include',
          method: 'POST',
          body: data
        }
      }
    })
  })
})

export const { useCreateMutation, useUpdateApartmentAcceptanceMutation } = downloadApi
