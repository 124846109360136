import { IUser, Roles } from '#api/types'

const isExpert = (user: IUser | null) => {
  if (!user) {
    return false
  }

  return user.role === Roles.EXPERT
}

export { isExpert }
