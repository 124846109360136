import { House } from '../../redux/api/types'

const getFullAddress = (house: House | null): string => {
  if (!house) {
    return ''
  }

  const drop = house.drop ? `/${house.drop}` : ''

  return `${house.street} ${house.number}${drop}`
}

export { getFullAddress }
