import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useApartmentsInfoByHomeMutation, useHouseMutation } from '#api/house-api'
import { House } from '#api/types'
import { LayoutPage, Table } from '#components'
import { useAppSelector } from '#store'
import { buildGroupByApartment, getFullAddress } from '#utils'

import FullScreenLoader from '../../components/FullScreenLoader'
import { useBreadcrumbs, useDataTable } from './hooks'

const ApartmentsPage = () => {
  const params = useParams()
  const types = useAppSelector((state) => state.houseState.types)
  const apartmentsInfoByHome = useAppSelector((state) => state.houseState.remarksApartmentsByHome)
  const [house, setHouse] = useState<House | null>(null)
  const [getHouse] = useHouseMutation()

  const [getApartmentsInfoByHome] = useApartmentsInfoByHomeMutation()
  const { houseId } = params ?? { houseId: 0 }

  useEffect(() => {
    getApartmentsInfoByHome(Number(houseId))
    getHouse(Number(houseId)).then((data) => {
      if ('data' in data) {
        setHouse(data.data)
      }
    })
  }, [])

  const groupByApartment = buildGroupByApartment(apartmentsInfoByHome)

  const { headCells, dataSource } = useDataTable({
    house,
    types,
    groupByApartment
  })

  if (!house) {
    return <FullScreenLoader />
  }

  const fullAddress = getFullAddress(house)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const breadcrumbs = useBreadcrumbs({ fullAddress })

  return (
    <LayoutPage breadcrumbs={breadcrumbs}>
      <Table
        isSelect={false}
        hasTableToolbar={true}
        tableName={fullAddress}
        propKey="id"
        defaultOrder="id"
        perPage={25}
        rowsPerPageOptions={[]}
        headCells={headCells}
        dataSource={dataSource as any[]}
      />
    </LayoutPage>
  )
}

export default ApartmentsPage
