import { createContext, ReactElement, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { useRemarksByApartmentMutation } from '#api/house-api'
import { RemarksByApartment, UnionRemarksByApartment } from '#api/types'

import { getRemarksByAcceptanceTypeId } from '../components/remark-checkbox/utils'
import { ApartmentProps } from './remark-context'

type RemarksAllViewContextState = {
  isOpenAllRemarks: boolean
  setIsOpenAllRemarks: (val: boolean) => void
  currentType: string
  setCurrentType: (val: string) => void
  currentRemarks: RemarksByApartment[]
  setCurrentRemarks: (currentState: RemarksByApartment[]) => void
  fetchActualRemarks: (remark: RemarksByApartment | UnionRemarksByApartment, paramsContext: ApartmentProps) => void
}

type Props = {
  children: ReactElement
}

const RemarksAllViewContext = createContext<RemarksAllViewContextState | null>(null)

const RemarksAllViewContextProvider = ({ children }: Props) => {
  const [isOpenAllRemarks, setIsOpenAllRemarks] = useState(false)
  const [currentRemarks, setCurrentRemarks] = useState<RemarksByApartment[]>([])
  const [currentType, setCurrentType] = useState<string>('')

  const [getRemarksByApartment] = useRemarksByApartmentMutation()

  const fetchActualRemarks = async (
    remark: RemarksByApartment | UnionRemarksByApartment,
    paramsContext: ApartmentProps
  ) => {
    const requestRemarksByApartment = await getRemarksByApartment(paramsContext)

    if (!('data' in requestRemarksByApartment)) {
      return toast.error('Ошибка при получении данных', {
        position: 'top-right'
      })
    }

    const actualRemarks = getRemarksByAcceptanceTypeId({
      currentRemark: remark,
      remarks: requestRemarksByApartment.data
    })

    setCurrentRemarks(actualRemarks)
  }

  const value = useMemo(
    () => ({
      fetchActualRemarks,
      isOpenAllRemarks,
      setIsOpenAllRemarks,
      currentRemarks,
      setCurrentRemarks,
      setCurrentType,
      currentType
    }),
    [isOpenAllRemarks, currentRemarks, currentType]
  )

  return <RemarksAllViewContext.Provider value={value}>{children}</RemarksAllViewContext.Provider>
}

export { RemarksAllViewContext, RemarksAllViewContextProvider }
