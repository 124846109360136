import { Home } from '@mui/icons-material'

import { BreadcrumbsType } from '../../../components/Breadcrumb'

type Props = {
  title: string
  fullAddress: string
  houseId?: string
}

const useBreadcrumbs = ({ title, fullAddress, houseId = '' }: Props) => {
  const breadcrumbs: BreadcrumbsType[] = [
    {
      to: '/house',
      text: (
        <>
          <Home sx={{ mr: 0.5 }} fontSize="inherit" />
          Дома
        </>
      )
    },
    {
      to: `/apartments/${houseId}`,
      text: fullAddress
    },
    {
      text: title
    }
  ]

  return breadcrumbs
}

export { useBreadcrumbs }
