import { Typography } from '@mui/material'

import { GridModify } from '../../style'

type Props = {
  fullAddress: string
  number: string
}

const ContactInfoRequestPdf = ({ fullAddress, number }: Props) => (
  <>
    <GridModify item xs={12}>
      <Typography textTransform="uppercase" align="center" variant="h6">
        Карточка Обращения
      </Typography>
    </GridModify>

    <GridModify item xs={12} />

    <GridModify item xs={4}>
      Ф.И.О заявителя:
    </GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={8}></GridModify>

    <GridModify item xs={4}>
      Контактный телефон:
    </GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={8}></GridModify>
    <GridModify item xs={4}>
      Почтовый адрес:
    </GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={8}></GridModify>

    <GridModify item xs={12} />

    <GridModify item xs={4}>
      Объект обращений:
    </GridModify>
    <GridModify item xs={8}>
      <Typography align="center" fontWeight="bold">
        ул.{fullAddress}, {number}
      </Typography>
    </GridModify>

    <GridModify item xs={4}>
      По соглашению №:
    </GridModify>
    <GridModify sx={{ borderBottom: '2px solid black', borderRightWidth: 0 }} item xs={3}></GridModify>
    <GridModify sx={{ borderBottom: '2px solid black', borderLeftWidth: 0 }} item xs={5}>
      от
    </GridModify>
  </>
)

export { ContactInfoRequestPdf }
