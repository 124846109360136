import { Typography } from '@mui/material'

import { GridBase, GridModify } from '../../style'

const MarkPerformanceRequestPdf = () => (
  <>
    <GridModify item xs={12}></GridModify>

    <GridModify item xs={2}>
      Дата составления:
    </GridModify>
    <GridModify item xs={1}></GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={2}>
      <Typography align="center">{new Date().toLocaleDateString()}</Typography>
    </GridModify>
    <GridModify item xs={2}></GridModify>
    <GridModify item xs={2}>
      Подпись заявителя
    </GridModify>
    <GridModify item xs={1}></GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={2}></GridModify>

    <GridModify item xs={12}></GridModify>

    <GridModify item xs={3}>
      Регистрационный номер:
    </GridModify>
    <GridModify item xs={1}></GridModify>
    <GridModify item xs={3} sx={{ borderBottom: '2px solid black' }}></GridModify>
    <GridModify item xs={1}></GridModify>
    <GridBase
      item
      xs={4}
      sx={{ borderTop: '2px solid black', borderRight: '2px solid black', borderLeft: '2px solid black' }}
    ></GridBase>

    <GridModify item xs={3}>
      Дата регистрации обращения:
    </GridModify>
    <GridModify item xs={1}></GridModify>
    <GridModify item xs={3} sx={{ borderBottom: '2px solid black' }}></GridModify>
    <GridModify item xs={1}></GridModify>
    <GridBase item xs={4} sx={{ borderRight: '2px solid black', borderLeft: '2px solid black' }}></GridBase>

    <GridModify item xs={8}></GridModify>
    <GridBase item xs={4} sx={{ borderRight: '2px solid black', borderLeft: '2px solid black' }}></GridBase>
    <GridModify item xs={8}></GridModify>
    <GridBase
      item
      xs={4}
      sx={{ borderRight: '2px solid black', borderLeft: '2px solid black', borderBottom: '2px solid black' }}
    ></GridBase>

    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={12}></GridModify>
    <GridModify item xs={12}>
      <Typography textTransform="uppercase" align="center" variant="h6">
        Отметка об исполнении
      </Typography>
    </GridModify>
    <GridModify item xs={12}></GridModify>

    <GridModify item xs={4}>
      Замечания заявиителя:
    </GridModify>
    <GridModify item xs={8}></GridModify>
    <GridModify sx={{ borderBottom: '2px solid black', minHeight: '60px' }} item xs={12}></GridModify>
    <GridModify item xs={12}>
      Замечания, изложенные в обращении, устранены в полном объеме и с надлежайщим качеством
    </GridModify>
    <GridModify item xs={12}></GridModify>

    <GridModify item xs={2}>
      Дата:
    </GridModify>
    <GridModify item xs={1}></GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={2}></GridModify>
    <GridModify item xs={2}></GridModify>
    <GridModify item xs={2}>
      Подпись заявителя
    </GridModify>
    <GridModify item xs={1}></GridModify>
    <GridModify sx={{ borderBottom: '2px solid black' }} item xs={2}></GridModify>
    <GridModify item xs={12}></GridModify>
  </>
)

export { MarkPerformanceRequestPdf }
