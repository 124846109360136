import { ColorRow } from '../../../config/constants'
import { ApartmentsAcceptanceType, House, RemarkByApartment, RemarksByApartment } from '../../../redux/api/types'
import { buildRelevantRemark, buildRemarksByType, getColorRow } from '../../../utils'

type Props = {
  house: House | null
  types: ApartmentsAcceptanceType[] | null
  remarksByApartment: RemarksByApartment[] | null
}

const useDataTable = (props: Props) => {
  const { types, house, remarksByApartment } = props

  if (types === null || house === null || remarksByApartment === null) {
    return {
      dataSource: []
    }
  }

  const remarksByType = buildRemarksByType(remarksByApartment)

  const dataSource: RemarkByApartment[] = types.map((type) => {
    const { slug, id } = type

    const remarks = remarksByType[id] ?? []

    const relevantRemark = buildRelevantRemark(remarks)
    const colorRow = relevantRemark ? getColorRow(relevantRemark) : { backgroundColor: ColorRow.WHITE }

    return {
      ['options.row-style']: colorRow,
      typeId: id,
      typesOfWork: slug,
      remarks,
      relevantRemark,
      created_at: relevantRemark?.created_at && new Date(relevantRemark?.created_at).toLocaleDateString(),
      remark: relevantRemark?.remark?.name,
      end_time: relevantRemark?.endTime,
      executor: relevantRemark?.user_id,
      user_specialist_id: relevantRemark?.user_specialist_id,
      is_mark: relevantRemark?.accepted,
      date_verify: relevantRemark?.date_verify,
      user_verify_id: relevantRemark?.user_verify_id
    }
  })

  return {
    dataSource
  }
}

export { type RemarkByApartment, useDataTable }
