import NotesIcon from '@mui/icons-material/Notes'
import { Button } from '@mui/material'
import { Fragment, useEffect } from 'react'

import { LayoutPage } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'

import { AccordionNotes, SidebarNotes } from './components'
import { NoteContext, NoteContextProvider } from './context'

const NotesPage = () => {
  const { getNotesByType, setIsOpen } = useContextSafe(NoteContext)

  useEffect(() => {
    getNotesByType(null)
  }, [])

  return (
    <LayoutPage
      isFixed={true}
      title="Замечания"
      afterTitleElement={
        <Button
          sx={{ maxWidth: '300px' }}
          startIcon={<NotesIcon />}
          variant="contained"
          onClick={() => setIsOpen(true)}
        >
          Создать замечание
        </Button>
      }
    >
      <Fragment>
        <AccordionNotes />

        <SidebarNotes />
      </Fragment>
    </LayoutPage>
  )
}

const NotesPageWithContext = () => (
  <NoteContextProvider>
    <NotesPage />
  </NoteContextProvider>
)

export default NotesPageWithContext
