import { createContext, ReactElement, useMemo, useState } from 'react'

type HouseContextState = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

type Props = {
  children: ReactElement
}

const HouseContext = createContext<HouseContextState | null>(null)

const HouseContextProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen
    }),
    [isOpen]
  )

  return <HouseContext.Provider value={value}>{children}</HouseContext.Provider>
}

export { HouseContext, HouseContextProvider }
