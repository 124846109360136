import { User } from '../../../redux/api/types'
import { getFullName } from '../../../utils'

const buildOptions = (users: User[]) =>
  users
    .map((user) => {
      const slug = getFullName(user)

      return {
        id: user.id,
        slug
      }
    })
    .sort(({ slug: aSlug }, { slug: bSlug }) => aSlug.localeCompare(bSlug))

export { buildOptions }
