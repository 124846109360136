import { createApi } from '@reduxjs/toolkit/query/react'

import { prepareHeadersFormDataToken, withBaseQuery } from '../helpers/prepare-headers'
import { IGenericResponse } from './types'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: withBaseQuery(`${BASE_URL}/api/house/`, prepareHeadersFormDataToken),
  endpoints: (builder) => ({
    downloadDocument: builder.mutation<IGenericResponse, FormData>({
      query(data) {
        return {
          url: 'uploading/document',
          method: 'POST',
          body: data
        }
      }
    })
  })
})

export const { useDownloadDocumentMutation } = documentApi
