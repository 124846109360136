import { Box, Typography } from '@mui/material'

import { RemarksByApartment } from '#api/types'

import { RemarksAcceptance } from './componentss'
import { GridModify, GridWrapper } from './style'

type Props = {
  fullAddress: string
  number: string
  remarks: RemarksByApartment[]
}

const ID_REMARK_CARD_PDF = 'remark-card-pdf'

const RemarkCardPdf = ({ fullAddress, number, remarks }: Props) => (
  <Box id={ID_REMARK_CARD_PDF} sx={{ display: 'none' }}>
    <GridWrapper container spacing={2} sx={{ width: '100%', ml: 0, mt: 0 }}>
      <GridModify item xs={12}>
        <Typography textTransform="uppercase" align="center" variant="h6">
          Карточка Замечаний
        </Typography>
      </GridModify>

      <GridModify item xs={12} />

      <GridModify item xs={4}>
        Объект обращений:
      </GridModify>
      <GridModify item xs={8}>
        <Typography align="center" fontWeight="bold">
          ул.{fullAddress}, {number}
        </Typography>
      </GridModify>

      <RemarksAcceptance remarks={remarks} />
    </GridWrapper>
  </Box>
)

export { RemarkCardPdf, ID_REMARK_CARD_PDF }
