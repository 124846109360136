import { User } from '#api/types'

const getFullName = (user: User | undefined) => {
  if (!user) {
    return ''
  }

  const { email } = user
  const { last_name, first_name } = user?.profile ?? { last_name: null, first_name: null }

  return !last_name && !first_name ? email : `${last_name ?? ''} ${first_name ?? ''}`
}

export { getFullName }
