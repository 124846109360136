import { useAppSelector } from '#store'
import { getFullAddress } from '#utils'

import { HeadCell } from '../../../../../components/table/type'

type UserByTable = {
  id: number
  email: string
  fullName: string
  position: string
  rolesUser: string
  houses: string
}

const useData = () => {
  const users = useAppSelector((state) => state.userState.users)

  const headCells: HeadCell[] = [
    {
      id: 'fullName',
      numeric: true,
      disablePadding: false,
      label: 'Полное имя'
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email'
    },
    {
      id: 'position',
      numeric: true,
      disablePadding: false,
      label: 'Должность'
    },
    {
      id: 'rolesUser',
      numeric: true,
      disablePadding: false,
      label: 'Роль',
      style: {
        width: '250px'
      }
    },
    {
      id: 'houses',
      numeric: true,
      disablePadding: false,
      label: 'Закрепленные дома',
      style: {
        width: '250px'
      }
    }
  ]

  const dataSource: UserByTable[] = (users ?? []).map((user) => {
    const { email, profile, roles = [], id, houses = [] } = user
    const { last_name = '', father_name = '', first_name = '', position = '' } = profile ?? {}
    const rolesUser = roles.map(({ slug }) => slug).join(', ')
    const housesFromString = houses.map((house) => getFullAddress(house)).join(', ')

    return {
      id,
      email,
      fullName: `${last_name} ${first_name} ${father_name}`,
      position,
      rolesUser,
      houses: housesFromString
    }
  })

  return {
    dataSource,
    headCells
  }
}

export { useData, type UserByTable }
