import { createContext, ReactElement, useMemo, useState } from 'react'

import { useNotesByTypeMutation } from '#api/note-api'
import { NotesByType } from '#api/types'
import { useAppSelector } from '#store'

type NoteContextState = {
  notesByType: NotesByType[]
  getNotesByType: (val: null) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

type Props = {
  children: ReactElement
}

const NoteContext = createContext<NoteContextState | null>(null)

const NoteContextProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [getNotesByType] = useNotesByTypeMutation()
  const notesByType = useAppSelector((state) => state.noteState.notesByType) ?? []

  const value = useMemo(
    () => ({
      notesByType,
      getNotesByType,
      isOpen,
      setIsOpen
    }),
    [notesByType, isOpen]
  )

  return <NoteContext.Provider value={value}>{children}</NoteContext.Provider>
}

export { NoteContext, NoteContextProvider }
