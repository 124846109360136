import { SyntheticEvent, useState } from 'react'

type Image = {
  image: string
  file: Blob
}

const useImages = () => {
  const [images, setImages] = useState<Image[]>([])

  const onDownloadFiles = ({ nativeEvent }: SyntheticEvent) => {
    const dom: EventTarget | any = nativeEvent?.target

    const imagesList = [...dom.files].map((file: Blob) => ({
      image: URL.createObjectURL(file),
      file
    }))

    setImages(imagesList)
  }

  const destroyImage = (idx: number) => {
    const filterImages = images.filter((_, i) => i !== idx)

    setImages(filterImages)
  }

  return {
    images,
    setImages,
    destroyImage,
    onDownloadFiles
  }
}

export { useImages }
