import { Toolbar } from '@mui/material'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode } from 'react'

type Props = {
  numSelected: number
  tableName?: string | ReactElement
  selected: any[]
  toolbarElement?: ReactNode
  filterElement?: ReactNode
  hasTableToolbar?: boolean
}

const TableToolbar = (props: Props) => {
  const { numSelected, tableName, toolbarElement = null, filterElement = null, hasTableToolbar = true } = props

  if (!hasTableToolbar) {
    return null
  }

  const renderToolbarElement = () => {
    if (!toolbarElement) {
      return <></>
    }

    return <>{toolbarElement}</>
  }

  const renderFilterElement = () => {
    if (!filterElement) {
      return <></>
    }

    return <>{filterElement}</>
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} Выбрано
        </Typography>
      ) : (
        tableName && (
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            {tableName}
          </Typography>
        )
      )}
      {numSelected > 0 ? renderToolbarElement() : renderFilterElement()}
    </Toolbar>
  )
}

export { TableToolbar }
