import { RemarkByApartment } from '../../../../redux/api/types'
import { useAppSelector } from '../../../../redux/store'
import { getFullName } from '../../../../utils'

type Props = {
  userId: string
  item: RemarkByApartment
  isSpecialist?: boolean
}

const RemarkUserName = ({ userId, item, isSpecialist = false }: Props) => {
  const users = useAppSelector((state) => state.userState.users) ?? []
  const remarkUserId = item.relevantRemark?.remark?.user_id

  if (!userId) {
    return <></>
  }

  const searchId = Number(userId) !== remarkUserId ? userId : remarkUserId
  const resultId = isSpecialist ? userId : searchId

  const user = users.find(({ id }) => id === Number(resultId))

  return <>{getFullName(user)}</>
}

export { RemarkUserName }
