import { Close } from '@mui/icons-material'
import AddHome from '@mui/icons-material/AddHome'
import { Box, Chip, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Theme, useTheme } from '@mui/material/styles'
import { useMemo } from 'react'

import { House } from '#api/types'
import { getFullAddress, keyBy } from '#utils'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const getStyles = (id: number, selectHouses: readonly number[], theme: Theme) => ({
  fontWeight: !selectHouses.includes(id) ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
})

type Props = {
  houses: House[]
  selectHouses: number[]
  setSelectHouses: (value: number[]) => void
}

const HouseSelect = ({ houses, selectHouses, setSelectHouses }: Props) => {
  const theme = useTheme()
  const houseById = useMemo(() => keyBy('id', houses), [])

  const handleChange = (event: SelectChangeEvent<typeof selectHouses>) => {
    const {
      target: { value }
    } = event

    setSelectHouses(value as number[])
  }

  const deleteSelectAddress = (value: number) => {
    const filterHouseSelects = selectHouses.filter((select) => select !== value)

    setSelectHouses(filterHouseSelects)
  }

  return (
    <div>
      <FormControl fullWidth>
        <Box sx={{ display: 'flex', mt: 2, mb: 2, flexWrap: 'wrap', gap: 0.5 }}>
          {selectHouses.map((value) => (
            <Chip
              key={value}
              label={
                <>
                  {getFullAddress(houseById[value])}
                  <IconButton size="small" onClick={() => deleteSelectAddress(value)}>
                    <Close />
                  </IconButton>
                </>
              }
            />
          ))}
        </Box>
        <InputLabel id="multiple-chip-label">Дома</InputLabel>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={selectHouses}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Дома" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value, index) => {
                const currentAddress = getFullAddress(houseById[value])
                const postfix = index === selected.length - 1 ? '' : ', '

                return currentAddress + postfix
              })}
              <IconButton size="small">
                <AddHome fontSize="small" />
              </IconButton>
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {houses.map((house) => {
            const fullAddress = getFullAddress(house)

            return (
              <MenuItem key={fullAddress} value={house.id} style={getStyles(house.id, selectHouses, theme)}>
                {fullAddress}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export { HouseSelect }
