import { Home } from '@mui/icons-material'

import { BreadcrumbsType } from '../../../components/Breadcrumb'

type Props = {
  fullAddress: string
}

const useBreadcrumbs = ({ fullAddress }: Props) => {
  const breadcrumbs: BreadcrumbsType[] = [
    {
      to: '/house',
      text: (
        <>
          <Home sx={{ mr: 0.5 }} fontSize="inherit" />
          Дома
        </>
      )
    },
    {
      text: fullAddress
    }
  ]

  return breadcrumbs
}

export { useBreadcrumbs }
