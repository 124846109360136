import { RemarksByApartment } from '#api/types'

import { getEndDate } from '../time'

const buildRelevantRemark = (remarks: RemarksByApartment[]) =>
  remarks.reduce((acc, item) => {
    const { created_at, period, remark = null } = item
    const completionDay = period ?? remark?.period ?? 0

    const endTime = getEndDate(created_at, completionDay).getTime()
    const currentEndTime = acc?.endTime ?? 0

    if (endTime > currentEndTime || acc === null) {
      return {
        endTime,
        ...item
      }
    }

    return acc
  }, null as any)

export { buildRelevantRemark }
