import { object, string, TypeOf } from 'zod'

const loginSchema = object({
  email: string().min(1, 'Поле Email обязательно').email('Не валидный Email адресс'),
  password: string()
    .min(1, 'Пароль должен содержать более 6 символов')
    .min(6, 'Пароль должен содержать более 6 символов')
    .max(32, 'Длина пароля должна составлять не более 32 символов')
})

type LoginInput = TypeOf<typeof loginSchema>

export { type LoginInput, loginSchema }
