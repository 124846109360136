import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useHouseMutation, useRemarksByApartmentMutation } from '#api/house-api'
import { Apartment } from '#api/types'
import { useUsersMutation } from '#api/userApi'
import { LayoutPage, Table } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'
import { getFullAddress, getNameByType } from '#utils'

import { type BreadcrumbsType } from '../../components/Breadcrumb'
import FullScreenLoader from '../../components/FullScreenLoader'
import {
  DeleteDocument,
  RemarkCardPdf,
  RemarkPrintBtn,
  RemarkRequestCardPdf,
  SidebarRemark,
  SidebarViewAllRemarks
} from './components'
import { Mode } from './components/sidebar-remark/sidebar-remark'
import {
  RemarkContext,
  RemarkContextProvider,
  RemarkNewContextProvider,
  RemarksAllViewContextProvider
} from './contexts'
import { useBreadcrumbs, useCell, useDataTable } from './hooks'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

const ApartmentPage = () => {
  const { remarksByApartment, setParamsContext } = useContextSafe(RemarkContext)
  const { isExpertRight } = useRightCurrentUser()

  const house = useAppSelector((state) => state.houseState.house)
  const params = useParams()

  const { apartmentId, houseId } = params ?? { apartmentId: 0, houseId: 0 }
  const types = useAppSelector((state) => state.houseState.types)
  const [getUsers] = useUsersMutation()
  const [getHouse] = useHouseMutation()
  const [getRemarksByApartment, { isLoading }] = useRemarksByApartmentMutation()
  const [apartment, setApartment] = useState<Apartment | null>(null)
  const nameByType = getNameByType(apartment?.type ?? 1)

  useEffect(() => {
    const findApartment = (house?.apartments ?? []).find(({ id }) => id === Number(apartmentId))

    if (findApartment) {
      setApartment(findApartment)
    }
  }, [house])

  useEffect(() => {
    getUsers(null)
    getHouse(Number(houseId))
  }, [])

  useEffect(() => {
    setParamsContext({
      apartmentId: Number(params.apartmentId),
      houseId: Number(params.houseId)
    })
  }, [params.apartmentId, params.houseId])

  useEffect(() => {
    if (!apartment?.id) {
      return
    }

    const params = {
      apartmentId: apartment.id,
      houseId: Number(houseId)
    }

    getRemarksByApartment(params)
  }, [apartment])

  const downloadDocument = () => {
    window.open(BASE_URL + `/remark/${params.houseId}/documents/${apartment?.document}`, '_blank')
  }

  const { headCells } = useCell()

  const { dataSource } = useDataTable({ types, house, remarksByApartment })

  const fullAddress = getFullAddress(house)
  const title = `${nameByType} №${apartment?.number}`

  const breadcrumbs: BreadcrumbsType[] = useBreadcrumbs({ title, fullAddress, houseId })

  if (!house || !apartment || isLoading) {
    return <FullScreenLoader />
  }

  const documentComponent = () => {
    if (isExpertRight) {
      return null
    }

    return (
      <small
        style={{ borderBottom: '1px solid black', cursor: 'pointer', marginLeft: '10px' }}
        onClick={downloadDocument}
      >
        {apartment?.document && 'Акт.pdf'}
      </small>
    )
  }

  return (
    <>
      <RemarkCardPdf number={title} fullAddress={fullAddress} remarks={remarksByApartment ?? []} />

      <RemarkRequestCardPdf number={title} house={house} fullAddress={fullAddress} remarks={remarksByApartment ?? []} />

      <LayoutPage breadcrumbs={breadcrumbs}>
        <>
          <Table
            isSelect={false}
            hasTableToolbar={true}
            tableName={
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <span>
                  {`${fullAddress} (${title})`}

                  {documentComponent()}
                  <DeleteDocument apartment={apartment} />
                </span>
                <RemarkPrintBtn apartment={apartment} />
              </Stack>
            }
            propKey="typesOfWork"
            defaultOrder="typesOfWork"
            perPage={25}
            rowsPerPageOptions={[]}
            headCells={headCells}
            dataSource={dataSource}
          />

          <SidebarRemark mode={Mode.CREATE} remarksByApartment={remarksByApartment ?? []} apartment={apartment} />

          <SidebarViewAllRemarks title={`${fullAddress} (${title})`} houseId={Number(houseId)} />
        </>
      </LayoutPage>
    </>
  )
}

const ApartmentPageWithContext = () => (
  <RemarkContextProvider>
    <RemarkNewContextProvider>
      <RemarksAllViewContextProvider>
        <ApartmentPage />
      </RemarksAllViewContextProvider>
    </RemarkNewContextProvider>
  </RemarkContextProvider>
)

export default ApartmentPageWithContext
