import { createContext, ReactElement, useMemo, useState } from 'react'

import { House } from '#api/types'

type UpdateContextState = {
  updateHouse: House | null
  setUpdateHouse: (house: House | null) => void
}

type Props = {
  children: ReactElement
}

const UpdateContext = createContext<UpdateContextState | null>(null)

const UpdateContextProvider = ({ children }: Props) => {
  const [updateHouse, setUpdateHouse] = useState<House | null>(null)

  const value = useMemo(
    () => ({
      updateHouse,
      setUpdateHouse
    }),
    [updateHouse]
  )

  return <UpdateContext.Provider value={value}>{children}</UpdateContext.Provider>
}

export { UpdateContext, UpdateContextProvider }
