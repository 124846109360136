import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IUser, Role, User } from '../api/types'

interface IUserState {
  user: IUser | null
  users: User[]
  roles: Role[]
  isPreloadingData: boolean
}

const initialState: IUserState = {
  user: null,
  users: [],
  roles: [],
  isPreloadingData: false
}

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload
    },
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload
    },
    setIsPreloadingData: (state, action: PayloadAction<boolean>) => {
      state.isPreloadingData = action.payload
    }
  }
})

export default userSlice.reducer

export const { logout, setIsPreloadingData, setUser, setUsers, setRoles } = userSlice.actions
