import { Print } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Container, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { useInfoLogMutation } from '#api/userApi'
import { usePreloadingData } from '#hooks/use-preloading-data'
import { useAppSelector } from '#store'
import { isAdmin } from '#utils'

import { setIsPreloadingData } from '../redux/features/userSlice'

const ProfilePage = () => {
  const [logs, setLogs] = useState('')
  const user = useAppSelector((state) => state.userState.user)
  const dispatch = useDispatch()
  const isPreloadingData = useAppSelector((state) => state.userState.isPreloadingData)
  const { fetchAllCollection } = usePreloadingData()
  const [getInfoLog, { isLoading }] = useInfoLogMutation()
  const isAdminRight = isAdmin(user)

  const onPrintLog = async () => {
    const response = await getInfoLog(null)

    if ('data' in response) {
      setLogs(response.data)
    }
  }

  useEffect(() => {
    if (isPreloadingData) {
      return
    }

    fetchAllCollection()
      .then((data) => {
        console.log(data)
        toast.success('Вы успешно вошли в систему, данные получены')
        dispatch(setIsPreloadingData(true))
      })
      .catch((err) => {
        console.log(err)
        toast.error('При формировании данных возникла ошибка')
        dispatch(setIsPreloadingData(false))
      })
  }, [])

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          backgroundColor: '#ece9e9',
          mt: '2rem',
          height: '15rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="h2" component="h1" sx={{ color: '#1f1e1e', fontWeight: 500 }}>
          Профиль
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography gutterBottom>
          <strong>Id:</strong> {user?.id}
        </Typography>
        <Typography gutterBottom>
          <strong>Email:</strong> {user?.email}
        </Typography>
        <Typography gutterBottom>
          <strong>Роль:</strong> {user?.role}
        </Typography>

        {isAdminRight && (
          <>
            <LoadingButton
              loading={isLoading}
              sx={{ mt: 2 }}
              onClick={onPrintLog}
              startIcon={<Print />}
              variant="contained"
            >
              Показать логи
            </LoadingButton>

            {logs && (
              <TextField sx={{ mt: 2, display: 'block' }} value={logs} fullWidth multiline rows={25} maxRows={25} />
            )}
          </>
        )}
      </Box>
    </Container>
  )
}

export default ProfilePage
