import { type RemarksByApartment, type RemarksByType } from '../../redux/api/types'

const buildGroupByApartment = (remarkByApartment: RemarksByApartment[]) =>
  remarkByApartment.reduce((acc, item) => {
    const { apartment_id } = item

    return {
      ...acc,
      [apartment_id]: apartment_id in acc ? [...acc[apartment_id], item] : [item]
    }
  }, {} as RemarksByType)

export { buildGroupByApartment }
