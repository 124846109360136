import { createContext, ReactElement, useMemo, useState } from 'react'

import { RemarkByApartment } from '../../../redux/api/types'

type RemarkNewContextProviderState = {
  detailedRemarkByApartment: RemarkByApartment | null
  setDetailedRemarkByApartment: (currentState: RemarkByApartment | null) => void
}

type Props = {
  children: ReactElement
}

const RemarkNewContext = createContext<RemarkNewContextProviderState | null>(null)

const RemarkNewContextProvider = ({ children }: Props) => {
  const [detailedRemarkByApartment, setDetailedRemarkByApartment] = useState<RemarkByApartment | null>(null)

  const value = useMemo(
    () => ({
      detailedRemarkByApartment,
      setDetailedRemarkByApartment
    }),
    [detailedRemarkByApartment]
  )

  return <RemarkNewContext.Provider value={value}>{children}</RemarkNewContext.Provider>
}

export { RemarkNewContext, RemarkNewContextProvider }
